import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EInventoryActionStatus, EInventoryActionType } from 'common/const/inventory.enum';
import { ERoute } from 'common/const/route.enum';
import { showMessage } from 'common/helpers/message.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, DEFAULT_VALUE_0, LIST_LIMIT_0, LIST_LIMIT_20 } from 'common/config';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { InventoryActionCard } from 'entities/Inventory/components/InventoryActionCard';
import { InventoryEmptyList } from 'entities/Inventory/components/InventoryEmptyList';
import { renderInventoryActionListRecords } from 'entities/Inventory/Inventory.helper';
import { IInventoryAction, IInventoryActionListPayload } from 'entities/Inventory/Inventory.models';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';
import { IUserInventoryStatistics } from 'entities/User/User.models';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  statistics: IUserInventoryStatistics;
  renderFilters: (value: IInventoryActionListPayload, setPayload: (value: IInventoryActionListPayload) => void) => ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    statistics,
    renderFilters,
    // state
    inventoryActionList,
    inventoryActionListCount,
    inventoryActionListLoading,
    inventoryActionLoading,
    currentUser,
    userInventoryStatisticsLoading,
    // dispatch
    getSubdivisionList,
    getUserList,
    setInventoryActionList,
    filterInventoryActionList,
    getInventoryActionList,
    getInventoryActionListPart,
    getInventoryActionById,
    deleteInventoryAction,
    setUserInventoryStatistics,
    getUserInventoryStatistics,
  } = props;

  const userId = currentUser?.id;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);

  const [payload, setPayload] = useState<IInventoryActionListPayload>({
    types: [EInventoryActionType.Transfer],
    statuses: [EInventoryActionStatus.New],
    userId,
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
  });
  const navigate = useNavigate();
  const {
    page,
    setPage,
    fetchLoading,
    setFetchLoading,
    setOpenInventoryActionCard,
    selectedInventoryActionList,
    setSelectedInventoryActionList,
    selectedInventoryActionListIds,
  } = useInventoryContext();

  const { outcomingTransfersCount } = statistics;
  const listLoading = inventoryActionListLoading || inventoryActionLoading || userInventoryStatisticsLoading || fetchLoading;
  const checkAll = inventoryActionList.length === selectedInventoryActionList.length;
  const indeterminate =
    selectedInventoryActionList.length > DEFAULT_VALUE_0 && selectedInventoryActionList.length < inventoryActionList.length;

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    e.target.checked ? setSelectedInventoryActionList(inventoryActionList) : setSelectedInventoryActionList([]);
  };

  const onInventoryActionSelectionChange = (id: number, checked: boolean) => {
    const action = inventoryActionList.find((item) => item.id === id) as IInventoryAction;
    const newSelectedInventoryActionList = checked
      ? [...selectedInventoryActionList, action]
      : selectedInventoryActionList.filter((item) => item.id !== id);

    setSelectedInventoryActionList(newSelectedInventoryActionList);
  };

  const onChangeRequestStatusSuccess = (records: IInventoryAction[]) => {
    const recordIds = records.map((record) => record.id);
    const newSelectedInventoryActionList = selectedInventoryActionList.filter((action) => !recordIds.includes(action.id));
    const filteredInventoryActionList = inventoryActionList.filter((action) => !recordIds.includes(action.id));

    setSelectedInventoryActionList(newSelectedInventoryActionList);
    filterInventoryActionList(records);
    setOpenInventoryActionCard(false);

    if (filteredInventoryActionList.length === LIST_LIMIT_0) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getInventoryActionList(newPayload);
    }
  };

  const onCancelRequestClick = (records: IInventoryAction[]) => {
    const recordIds = records.map((record) => record.id);

    deleteInventoryAction({
      ids: recordIds,
      onSuccess: () => {
        const newOutcomingTransfersCount = outcomingTransfersCount - recordIds.length;

        showMessage({
          content: 'Запрос о передаче оборудования отменен.',
          icon: <BinIcon className="icon-bin-dark-grey" />,
        });

        if (newOutcomingTransfersCount === DEFAULT_VALUE_0) {
          navigate(ERoute.InventoryList);
        } else {
          setUserInventoryStatistics({ ...statistics, outcomingTransfersCount: newOutcomingTransfersCount });
          filterInventoryActionList(records);
          onChangeRequestStatusSuccess(records);
        }
      },
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setInventoryActionList([]);
      setSelectedInventoryActionList([]);

      const response = await getUserInventoryStatistics();

      if (response && response.outcomingTransfersCount === DEFAULT_VALUE_0) {
        navigate(ERoute.InventoryList);
        return;
      }

      if (isAccountAdmin) {
        await getSubdivisionList({ limit: LIST_LIMIT_0 });
        await getUserList({ limit: LIST_LIMIT_0 });
      }

      await getInventoryActionList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!inventoryActionListLoading && inventoryActionList.length < inventoryActionListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getInventoryActionListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}
        {renderFilters(payload, setPayload)}

        {!!inventoryActionList.length && (
          <div className="inventory-list__actions">
            <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
              Выбрать заявки
            </Checkbox>

            {!!selectedInventoryActionList.length && (
              <Button className="button-s danger" onClick={() => onCancelRequestClick(selectedInventoryActionList)}>
                Отменить все
              </Button>
            )}
          </div>
        )}

        <Table
          className={`
                table-hover inventory-list__table
                ${
                  !!inventoryActionList.length &&
                  inventoryActionList.length === inventoryActionListCount &&
                  'inventory-list__table-end-of-list'
                }
                ${!inventoryActionList.length && 'inventory-list__table-empty'}
              `}
          dataSource={inventoryActionList.map((inventory) => ({ ...inventory, key: inventory.id }))}
          columns={renderInventoryActionListRecords({ onCancelRequestClick })}
          showHeader={false}
          pagination={false}
          rowSelection={{
            selectedRowKeys: selectedInventoryActionListIds,
            onSelect: ({ id }, checked) => onInventoryActionSelectionChange(id, checked),
            columnWidth: 40,
          }}
          onRow={({ id }) => ({
            onClick: async () => {
              await getInventoryActionById(id);
              setOpenInventoryActionCard(true);
            },
          })}
          locale={{
            emptyText: <InventoryEmptyList open={!(inventoryActionListLoading || fetchLoading) && !inventoryActionList.length} />,
          }}
          loading={{ spinning: listLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <InventoryActionCard onCancel={onCancelRequestClick} />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  inventoryActionList: state.inventoryActionListState.data,
  inventoryActionListCount: state.inventoryActionListState.count,
  inventoryActionListLoading: state.inventoryActionListState.loading,
  inventoryActionLoading: state.inventoryActionState.loading,
  currentUser: state.userState.currentUser,
  userInventoryStatisticsLoading: state.userInventoryStatisticsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getUserList: dispatch.userListState.getUserList,
  setInventoryActionList: dispatch.inventoryActionListState.setInventoryActionList,
  filterInventoryActionList: dispatch.inventoryActionListState.filterList,
  getInventoryActionList: dispatch.inventoryActionListState.getInventoryActionList,
  getInventoryActionListPart: dispatch.inventoryActionListState.getInventoryActionListPart,
  getInventoryActionById: dispatch.inventoryActionState.getInventoryActionById,
  deleteInventoryAction: dispatch.inventoryActionState.deleteInventoryAction,
  setUserInventoryStatistics: dispatch.userInventoryStatisticsState.setUserInventoryStatistics,
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const TransferSentInventoryActionList = connect(mapState, mapDispatch)(Component);
