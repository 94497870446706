export enum ECreateWorkspaceCardMode {
  Create = 'create',
  Edit = 'edit',
  Copy = 'copy',
}

export enum EEditPositionCardMode {
  Edit = 'edit',
  Copy = 'copy',
}
