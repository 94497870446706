import React, { FC } from 'react';

interface IComponentProps {
  code?: string;
}

export const BuyerCode: FC<IComponentProps> = ({ code }) => {
  if (!code) {
    return null;
  }

  return (
    <span className="redesign buyer-code">
      <span className="text-tag color-dark-grey">Артикул клиента:</span>

      <span className="text-tag-accent color-bright-green buyer-code__value">{code}</span>
    </span>
  );
};
