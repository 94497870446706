import React, { FC, useState } from 'react';
import { Form, InputNumber } from 'antd';
import { EFormFieldMessage } from 'common/const/form.enum';
import { IProperty } from 'entities/Property/Property.models';

interface IComponentProps {
  property: IProperty;
  fieldClassName?: string;
}

export const PropertyRange: FC<IComponentProps> = ({ property, fieldClassName }) => {
  const [error, setError] = useState<EFormFieldMessage | null>();

  const { id, displayName, unitOfMeasurement, isRequiredForPresets } = property;
  const unit = unitOfMeasurement ? `, ${unitOfMeasurement}` : '';
  const label = isRequiredForPresets ? `${displayName}${unit}*` : `${displayName}${unit}`;

  return (
    <Form.Item
      className={`property-range ${fieldClassName}`}
      label={label}
      validateStatus={error ? 'error' : 'success'}
      help={error}
    >
      <div className={`property-range__container ${error ? 'has-error' : ''}`}>
        <Form.Item
          name={[id, 0, 'from']}
          noStyle
          dependencies={[id, 0, 'to']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const to = getFieldValue([id, 0, 'to']);

                if (isRequiredForPresets && !value && !to) {
                  setError(EFormFieldMessage.RequiredField);
                  return Promise.reject();
                }

                if (value && to && value > to) {
                  setError(EFormFieldMessage.InvalidRange);
                  return Promise.reject();
                }

                setError(null);
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber prefix="от" controls={false} />
        </Form.Item>

        <span className="text-controls color-dark-grey">-</span>

        <Form.Item
          name={[id, 0, 'to']}
          noStyle
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const from = getFieldValue([id, 0, 'from']);

                if (isRequiredForPresets && !value && !from) {
                  setError(EFormFieldMessage.RequiredField);
                  return Promise.reject();
                }

                if (value && from && value < from) {
                  setError(EFormFieldMessage.InvalidRange);
                  return Promise.reject();
                }

                setError(null);
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber prefix="до" controls={false} />
        </Form.Item>
      </div>
    </Form.Item>
  );
};
