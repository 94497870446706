import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { ECreateWorkspaceCardMode } from 'common/const/workspace.enum';
import { ERoute } from 'common/const/route.enum';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { IFormValues } from 'common/models';
import { SELECT_LIST_HEIGHT_320 } from 'common/config';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { mapSubdivisionListToSubdivisionOptions } from 'entities/Subdivisions/Subdivisions.helper';
import { mapUserListToUserOptions } from 'entities/User/User.helper';
import { IWorkspace } from 'entities/Workspace/Workspace.models';

interface IComponentProps {
  open: boolean;
  mode: ECreateWorkspaceCardMode;
  workspace: IWorkspace | null;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    open,
    mode,
    workspace,
    onOpenChange,
    onClose,
    // state
    subdivisionList,
    userList,
    workspaceLoading,
    // dispatch
    createWorkspace,
    updateWorkspace,
    copyWorkspace,
  } = props;

  const [subdivisionSearch, setSubdivisionSearch] = useState<string>('');
  const [userSearch, setUserSearch] = useState<string>('');
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const isEditMode = mode === ECreateWorkspaceCardMode.Edit;
  const isCopyMode = mode === ECreateWorkspaceCardMode.Copy;

  const handleClose = () => {
    onClose();
    setFormIsChanged(false);
    setOpenWarningDrawer(false);
    setSubdivisionSearch('');
    setUserSearch('');
    form.resetFields();
  };

  const onCloseClick = () => {
    if (workspaceLoading) {
      return;
    }

    if (formIsChanged) {
      setOpenWarningDrawer(true);
      onOpenChange(false);
      return;
    }

    handleClose();
  };

  const onCreateSubmit = async ({ name, subdivisionId, userId }: IFormValues) => {
    const response = await createWorkspace({
      name,
      subdivisionId,
      userId,
      onSuccess: () => {
        handleClose();
        showSuccessMessage('Новая карта оснащения добавлена в подразделение.');
      },
    });

    if (response) {
      navigate(`${ERoute.WorkspaceList}/${response.id}`);
    }
  };

  const onEditSubmit = (values: IFormValues) => {
    if (workspace) {
      updateWorkspace({
        id: workspace.id,
        ...values,
        onSuccess: () => {
          handleClose();
          showSuccessMessage('Изменения в карте оснащения сохранены.');
        },
      });
    }
  };

  const onCopySubmit = ({ name, subdivisionId, userId }: IFormValues) => {
    if (workspace) {
      copyWorkspace({
        workspaceId: workspace.id,
        name,
        subdivisionId,
        userId,
        onSuccess: () => {
          handleClose();
          showSuccessMessage('Новая карта оснащения добавлена в подразделение.');
        },
      });
    }
  };

  useEffect(() => {
    if (workspace) {
      if (isEditMode) {
        form.setFieldsValue({
          name: workspace.name,
          subdivisionId: workspace.subdivision?.id,
          userId: workspace.user?.id,
        });
      }

      if (isCopyMode) {
        form.setFieldsValue({ name: `(Копия) ${workspace.name}` });
      }
    }
  }, [workspace, isEditMode, isCopyMode]);

  return (
    <>
      <Drawer className="redesign drawer" open={open} width={720} onClose={onCloseClick}>
        <div className="drawer__body">
          <div className="drawer__title">Новая карта оснащения</div>

          <Form
            layout="vertical"
            form={form}
            requiredMark={false}
            onValuesChange={() => setFormIsChanged(true)}
            onFinish={isEditMode ? onEditSubmit : isCopyMode ? onCopySubmit : onCreateSubmit}
          >
            <Form.Item className="mb-32" label="Название*">
              <Form.Item className="mb-0" name="name" rules={[rules.required()]}>
                <Input placeholder={EPlaceholder.EnterWorkspaceName} />
              </Form.Item>
            </Form.Item>

            <Form.Item className="mb-32" label="Подразделение*" shouldUpdate>
              {() => {
                const subdivisionOptions = mapSubdivisionListToSubdivisionOptions(subdivisionList, subdivisionSearch);

                return (
                  <Form.Item className="mb-0" name="subdivisionId" rules={[rules.required()]}>
                    <Select
                      rootClassName="redesign"
                      listHeight={SELECT_LIST_HEIGHT_320}
                      virtual={false}
                      placeholder={EPlaceholder.SelectSubdivision}
                      suffixIcon={<ArrowDownShortIcon />}
                      options={subdivisionOptions}
                      notFoundContent="В списке нет вариантов."
                      onChange={() => form.resetFields(['userId'])}
                      dropdownRender={(menus) => {
                        return (
                          <>
                            <Input
                              className="mb-20"
                              allowClear={{ clearIcon: <CloseIcon /> }}
                              value={subdivisionSearch}
                              onChange={(e) => setSubdivisionSearch(e.target.value)}
                            />

                            {menus}
                          </>
                        );
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item label="Присвоить сотруднику" shouldUpdate>
              {({ getFieldValue }) => {
                const subdivisionId = getFieldValue('subdivisionId');
                const filteredUserList = userList.filter((user) => (subdivisionId ? user.subdivisionId === subdivisionId : true));
                const userOptions = mapUserListToUserOptions(filteredUserList, userSearch);

                return (
                  <Form.Item
                    name="userId"
                    rules={[
                      () => ({
                        validator(_, value) {
                          const user = userList.find((item) => item.id === value);

                          if (!user?.workspaceId || (isEditMode && workspace?.id === user?.workspaceId)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('Сотруднику уже присвоена другая карта оснащения.'));
                        },
                      }),
                    ]}
                  >
                    <Select
                      rootClassName="redesign"
                      listHeight={SELECT_LIST_HEIGHT_320}
                      virtual={false}
                      placeholder={EPlaceholder.SelectEmployee}
                      suffixIcon={<ArrowDownShortIcon />}
                      options={userOptions}
                      notFoundContent="В списке нет вариантов."
                      dropdownRender={(menus) => {
                        return (
                          <>
                            <Input
                              className="mb-20"
                              allowClear={{ clearIcon: <CloseIcon /> }}
                              value={userSearch}
                              onChange={(e) => setUserSearch(e.target.value)}
                            />

                            {menus}
                          </>
                        );
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Form>
        </div>

        <div className="drawer__footer" style={{ justifyContent: 'flex-end' }}>
          <Button className="button-l primary" onClick={() => form.submit()} loading={workspaceLoading}>
            Сохранить карту
          </Button>
        </div>
      </Drawer>

      <WarningDrawer
        open={openWarningDrawer}
        content="Вы уверены, что хотите вернуться без сохранения карты оснащения?"
        subtitle="Все введённые вами данные будут потеряны."
        confirmBtnTitle="Не сохранять"
        loading={false}
        onClose={() => {
          setOpenWarningDrawer(false);
          onOpenChange(true);
        }}
        onConfirm={handleClose}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.subdivisionListState.data,
  userList: state.userListState.data,
  workspaceLoading: state.workspaceState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  createWorkspace: dispatch.workspaceState.createWorkspace,
  updateWorkspace: dispatch.workspaceState.updateWorkspace,
  copyWorkspace: dispatch.workspaceState.copyWorkspace,
});

export const CreateWorkspaceCard = connect(mapState, mapDispatch)(Component);
