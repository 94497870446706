import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { IFormValues } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { CatalogSettingsTree } from 'entities/Settings/components/CatalogSettingsTree';
import { CatalogSettingsForm } from 'entities/Settings/components/CatalogSettingsForm';
import { CreateSubcategoryModal } from 'entities/Modal/components/CreateSubcategoryModal';
import { propertyListToCategoryTemplateTreeData } from 'entities/Categories/Categories.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CatalogSettingsComponent: FC<AllType> = (props) => {
  const {
    categoryListState,
    propertyListState,
    catalogState,
    categoryState,
    getCategoryList,
    getPropertyList,
    getCatalogById,
    createCategory,
    deleteCategory,
    selectCategory,
    setCategory,
    setPropertyList,
  } = props;

  const { data: categoryList } = categoryListState;
  const { data: propertyList } = propertyListState;
  const { data: catalog } = catalogState;
  const { data: category, loading: categoryLoading } = categoryState;

  const [openCreateSubcategoryModal, setOpenCreateSubcategoryModal] = useState<boolean>(false);
  const [parentCategoryId, setParentCategoryId] = useState<number | undefined>();
  const { id } = useParams();
  const [form] = useForm();

  const categoryTemplateTreeData = propertyListToCategoryTemplateTreeData(propertyList);

  const handleCreateSubcategory = (parentCategoryId?: number) => {
    if (parentCategoryId) {
      setParentCategoryId(parentCategoryId);
    }

    setOpenCreateSubcategoryModal(true);
  };

  const onCreateSubcategoryModalCancelClick = () => {
    setOpenCreateSubcategoryModal(false);
    setParentCategoryId(undefined);
    form.resetFields();
  };

  const onCreateSubcategoryModalSubmit = (value: IFormValues) => {
    const { name } = value;

    if (id) {
      createCategory({
        name,
        displayName: name,
        catalogId: Number(id),
        parentId: parentCategoryId,
        onSuccess: onCreateSubcategoryModalCancelClick,
      });
    }
  };

  useEffect(() => {
    return () => {
      setCategory(null);
      setPropertyList([]);
    };
  }, []);

  const { contentLoading } = useContentLoader(async () => {
    if (id) {
      await getCategoryList({ catalogId: Number(id), limit: 0 });
      await getPropertyList({ limit: 0 });
      await getCatalogById(Number(id));
    }
  }, [id]);

  return (
    <div className="catalog-settings">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <CatalogSettingsTree
            catalogName={catalog?.name}
            categoryList={categoryList}
            createSubcategory={handleCreateSubcategory}
            deleteSubcategory={deleteCategory}
            selectSubcategory={selectCategory}
          />

          <CatalogSettingsForm
            catalog={catalog}
            category={category}
            loading={categoryLoading}
            categoryTemplateTreeData={categoryTemplateTreeData}
            propertyList={propertyList}
          />
        </>
      )}

      <CreateSubcategoryModal
        open={openCreateSubcategoryModal}
        form={form}
        loading={categoryLoading}
        onCancelClick={onCreateSubcategoryModalCancelClick}
        onCreateSubmit={onCreateSubcategoryModalSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  categoryListState: state.categoryListState,
  propertyListState: state.propertyListState,
  catalogState: state.catalogState,
  categoryState: state.categoryState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCategoryList: dispatch.categoryListState.getCategoryList,
  getPropertyList: dispatch.propertyListState.getPropertyList,
  getCatalogById: dispatch.catalogState.getCatalogById,
  createCategory: dispatch.categoryState.createCategory,
  deleteCategory: dispatch.categoryState.deleteCategory,
  selectCategory: dispatch.categoryState.selectCategory,
  setCategory: dispatch.categoryState.setCategory,
  setPropertyList: dispatch.propertyListState.setPropertyList,
});

export const CatalogSettings = connect(mapState, mapDispatch)(CatalogSettingsComponent);
