import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { TabNavigation } from 'common/components/TabNavigation';
import { ERoute } from 'common/const/route.enum';
import { EInventoryListNavigationTab } from 'common/const/inventory.enum';
import { InventoryProvider } from 'common/hooks/useInventoryContext';
import { GoodsProvider } from 'common/hooks/useGoodsContext';
import { DEFAULT_VALUE_0 } from 'common/config';
import { RootState } from 'app/store';
import { WriteOffInventoryActionList } from 'entities/Inventory/components/WriteOffInventoryActionList';
import { TransferInventoryActionList } from 'entities/Inventory/components/TransferInventoryActionList';
import { AllInventoryList } from 'entities/Inventory/components/AllInventoryList';
import { getUserRole } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState>;

export const Component: FC<AllType> = ({ currentUser, userInventoryStatistics }) => {
  const { tab } = useParams();

  const { incomingTransfersCount, outcomingTransfersCount, writeOffsCount } = userInventoryStatistics;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const isTransferInventoryActionList = tab === EInventoryListNavigationTab.Transfer;
  const isWriteOffInventoryActionList = tab === EInventoryListNavigationTab.WriteOff;
  const header = <div className="text-body color-dark-grey mb-20">Учёт инструмента</div>;

  const getTabNavigationItems = () => {
    const items = [{ label: isAccountAdmin ? 'Все инструменты' : 'Мои инструменты', path: ERoute.InventoryList as string }];

    if (incomingTransfersCount > DEFAULT_VALUE_0 || outcomingTransfersCount > DEFAULT_VALUE_0) {
      items.push({
        label: isAccountAdmin ? 'Запросы о передаче' : 'Передача инструмента',
        path: `${ERoute.InventoryList}/${EInventoryListNavigationTab.Transfer}`,
      });
    }

    if (isAccountAdmin && writeOffsCount > DEFAULT_VALUE_0) {
      items.push({
        label: 'Запросы о списании',
        path: `${ERoute.InventoryList}/${EInventoryListNavigationTab.WriteOff}`,
      });
    }

    return items;
  };

  const navigation = <TabNavigation items={getTabNavigationItems()} />;

  return (
    <InventoryProvider>
      <GoodsProvider>
        <div className="redesign inventory-list">
          {isWriteOffInventoryActionList ? (
            <WriteOffInventoryActionList header={header} navigation={navigation} statistics={userInventoryStatistics} />
          ) : isTransferInventoryActionList ? (
            <TransferInventoryActionList header={header} navigation={navigation} statistics={userInventoryStatistics} />
          ) : (
            <AllInventoryList header={header} navigation={navigation} statistics={userInventoryStatistics} />
          )}
        </div>
      </GoodsProvider>
    </InventoryProvider>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  userInventoryStatistics: state.userInventoryStatisticsState.data,
});

export const InventoryList = connect(mapState)(Component);
