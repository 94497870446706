export enum EInventoryListNavigationTab {
  Transfer = 'transfer',
  WriteOff = 'write-off',
}

export enum EInventoryListMenuTab {
  Available = 'available',
  WriteOffActionList = 'write_off_action_list',
  WriteOff = 'write_off',
  Incoming = 'incoming',
  Sent = 'sent',
}

export enum EInventoryStatus {
  Available = 'available',
  UnderRepair = 'under_repair',
  WriteOff = 'write_off',
}

export enum EInventoryActionType {
  WriteOff = 'write_off',
  Transfer = 'transfer',
}

export enum EInventoryActionStatus {
  New = 'new',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
}

export enum EInventoryActionReason {
  ToolDamage = 'tool_damage',
  ToolLoss = 'tool_loss',
  ScheduledReplacement = 'scheduled_replacement',
  Other = 'other',
}

export enum EInventoryActionReasonLabel {
  tool_damage = 'Поломка или повреждение инструмента',
  tool_loss = 'Утеря инструмента или его части',
  scheduled_replacement = 'Плановая замена оборудования',
  other = 'Другое',
}
