export enum EPlaceholder {
  SearchGoodsByNameOrVendorCode = 'Искать по названию или артикулу товара',
  AddVendorCode = 'Добавить артикул',
  SelectAddress = 'Выберите адрес',
  SelectLegal = 'Выберите юр.лицо',
  EnterFirstName = 'Введите имя',
  EnterLastName = 'Введите фамилию',
  EnterEmail = 'Введите email',
  EnterPhone = 'Введите номер телефона',
  EnterOldPassword = 'Введите старый пароль',
  EnterNewPassword = 'Введите новый пароль',
  RepeatNewPassword = 'Введите новый пароль ещё раз',
  EnterWorkspaceName = 'Введите название карты',
  SelectSubdivision = 'Выберите подразделение',
  SelectEmployee = 'Выберите сотрудника',
  SearchByTemplates = 'Поиск по шаблонам',
  Select = 'Выбрать',
  SelectCompany = 'Выберите компанию',
  SelectUser = 'Выберите пользователя',
  SearchByNameOrArticle = 'Искать по названию или артикулу',
  SelectReasonForWriting = 'Выберите причину списания',
  ProvideAdditionalDetailsAboutReasonForWriteOff = 'Укажите дополнительные сведения о причине списания',
}
