import React, { FC, useMemo, useState } from 'react';
import { Button, Select } from 'antd';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { LIST_LIMIT_0 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import {
  discountsToContractDiscountSelectOptions,
  findCategoryTreeExpandedKeysBySearch,
  findInitialCategoryTreeExpandedKeys,
  getCategorySettingsTreeData,
  getContractName,
} from 'entities/Contract/Contract.helper';
import { ContractRow } from 'entities/Contract/components/ContractRow';
import { ContractCategorySettingsModal } from 'entities/Modal/components/ContractCategorySettingsModal';
import { ContractBrandSettingsModal } from 'entities/Modal/components/ContractBrandSettingsModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ContractComponent: FC<AllType> = (props) => {
  const {
    discountListState,
    categoryListState,
    brandListState,
    contractState,
    currentUser,
    getDiscountList,
    getCatalogList,
    getCategoryList,
    getBrandList,
    getContractById,
    updateContract,
  } = props;

  const { data: discountList } = discountListState;
  const { data: categoryList } = categoryListState;
  const { data: brandList } = brandListState;
  const { data: contract, loading: contractLoading } = contractState;

  const [discountId, setDiscountId] = useState<number | null>(null);
  const [search, setSearch] = useState<string>('');
  const [availableCategories, setAvailableCategories] = useState<number[]>([]);
  const [categorySettingsExpandedKeys, setCategorySettingsExpandedKeys] = useState<number[]>([]);
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);
  const [openCategorySettingsModal, setOpenCategorySettingsModal] = useState<boolean>(false);
  const [openBrandSettingsModal, setOpenBrandSettingsModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const sellerId = currentUser?.accountId;
  const discountSelectOptions = discountsToContractDiscountSelectOptions(discountList);
  const contractName = getContractName(contract);
  const categoryListIds = useMemo(() => categoryList.map((category) => category.id), [categoryList]);
  const categorySettingsTreeData = useMemo(() => getCategorySettingsTreeData(categoryList), [categoryList]);
  const categorySettingsCheckedAll = availableCategories.length === categoryList.length;
  const categorySettingsIndeterminate = availableCategories.length > 0 && availableCategories.length < categoryList.length;
  const brandSettingsCheckedAll = availableBrands.length === brandList.length;
  const brandSettingsIndeterminate = availableBrands.length > 0 && availableBrands.length < brandList.length;

  const onDiscountChange = (value: number) => {
    setDiscountId(value);

    if (contract) {
      updateContract({ id: contract.id, discountId: value });
    }
  };

  const onCategorySettingsClick = () => {
    if (contract) {
      if (contract.availableCategories) {
        const initialCategoryTreeExpandedKeys = findInitialCategoryTreeExpandedKeys(categoryList, contract.availableCategories);

        setCategorySettingsExpandedKeys(initialCategoryTreeExpandedKeys);
        setAvailableCategories(contract.availableCategories);
      } else {
        setCategorySettingsExpandedKeys(categoryListIds);
        setAvailableCategories(categoryListIds);
      }

      setOpenCategorySettingsModal(true);
    }
  };

  const onContractCategorySettingsModalSearch = (value: string) => {
    const expandedKeys = findCategoryTreeExpandedKeysBySearch(categoryList, value);

    setSearch(value);
    setCategorySettingsExpandedKeys(expandedKeys);
  };

  const onContractCategorySettingsModalCheckAll = () => {
    categorySettingsCheckedAll ? setAvailableCategories([]) : setAvailableCategories(categoryListIds);
  };

  const onContractCategorySettingsModalCancelClick = () => {
    setSearch('');
    setAvailableCategories([]);
    setCategorySettingsExpandedKeys([]);
    setOpenCategorySettingsModal(false);
  };

  const onContractCategorySettingsModalConfirmClick = () => {
    if (contract) {
      updateContract({ id: contract.id, availableCategories, onSuccess: onContractCategorySettingsModalCancelClick });
    }
  };

  const onBrandSettingsClick = () => {
    if (contract) {
      if (contract.availableBrands) {
        setAvailableBrands(contract.availableBrands);
      } else {
        setAvailableBrands(brandList);
      }

      setOpenBrandSettingsModal(true);
    }
  };

  const onContractBrandSettingsModalCheckedKeysChange = (value: string, checked: boolean) => {
    if (checked) {
      setAvailableBrands((prev) => [...prev, value]);
    } else {
      setAvailableBrands((prev) => prev.filter((brand) => brand !== value));
    }
  };

  const onContractBrandSettingsModalCheckAll = () => {
    brandSettingsCheckedAll ? setAvailableBrands([]) : setAvailableBrands(brandList);
  };

  const onContractBrandSettingsModalCancelClick = () => {
    setSearch('');
    setAvailableBrands([]);
    setOpenBrandSettingsModal(false);
  };

  const onContractBrandSettingsModalConfirmClick = () => {
    if (contract) {
      updateContract({ id: contract.id, availableBrands, onSuccess: onContractBrandSettingsModalCancelClick });
    }
  };

  const { contentLoading } = useContentLoader(async () => {
    const contract = await getContractById(Number(id));
    const catalogList = await getCatalogList({ limit: LIST_LIMIT_0 });
    const catalog = catalogList?.find((item) => item.sellerId === sellerId);

    if (contract && contract.discountId) {
      setDiscountId(contract.discountId);
    }

    await getDiscountList({ limit: 0 });
    await getBrandList();

    if (catalog) {
      await getCategoryList({ catalogId: catalog.id, limit: 0 });
    }
  });

  return (
    <div className="contract">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <div className="contract__title">
            <span className="contract__title-prefix">Контракты</span>

            <span>{` / ${contractName}`}</span>
          </div>

          <div className="contract__container">
            <ContractRow title="Скидки" description="Выбрать из списка доступных видов скидок">
              <Select
                options={discountSelectOptions}
                value={discountId}
                onChange={onDiscountChange}
                className="contract-row__select"
                placeholder="Выберите вид скидок"
                suffixIcon={<ChevronIcon />}
                popupMatchSelectWidth={false}
                placement="bottomRight"
              />
            </ContractRow>

            <ContractRow title="Категории" description="Настроить ассортимент, доступный к заказу">
              <Button className="btn btn-default" onClick={onCategorySettingsClick}>
                Настроить
              </Button>
            </ContractRow>

            <ContractRow title="Бренды" description="Настроить ассортимент, доступный к заказу">
              <Button className="btn btn-default" onClick={onBrandSettingsClick}>
                Настроить
              </Button>
            </ContractRow>

            <ContractRow
              className="delivery"
              title="Сроки доставки"
              description="Указать сроки доставки со складов продавца до покупателя"
              onClick={() => navigate(`/admin-contract-list/${id}/delivery/${contract?.buyerId}`)}
            >
              <ChevronIcon />
            </ContractRow>
          </div>
        </>
      )}

      <ContractCategorySettingsModal
        open={openCategorySettingsModal}
        loading={contractLoading}
        defaultTreeData={categorySettingsTreeData}
        search={search}
        checkedAll={categorySettingsCheckedAll}
        indeterminate={categorySettingsIndeterminate}
        checkedKeys={availableCategories}
        expandedKeys={categorySettingsExpandedKeys}
        changeSearch={onContractCategorySettingsModalSearch}
        changeCheckedKeys={setAvailableCategories}
        changeExpandedKeys={setCategorySettingsExpandedKeys}
        onCheckAll={onContractCategorySettingsModalCheckAll}
        onCancel={onContractCategorySettingsModalCancelClick}
        onConfirm={onContractCategorySettingsModalConfirmClick}
      />

      <ContractBrandSettingsModal
        open={openBrandSettingsModal}
        loading={contractLoading}
        search={search}
        checkedAll={brandSettingsCheckedAll}
        indeterminate={brandSettingsIndeterminate}
        checkedKeys={availableBrands}
        brandList={brandList}
        changeSearch={setSearch}
        changeCheckedKeys={onContractBrandSettingsModalCheckedKeysChange}
        onCheckAll={onContractBrandSettingsModalCheckAll}
        onCancel={onContractBrandSettingsModalCancelClick}
        onConfirm={onContractBrandSettingsModalConfirmClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  discountListState: state.discountListState,
  categoryListState: state.categoryListState,
  brandListState: state.brandListState,
  contractState: state.contractState,
  currentUser: state.userState.currentUser,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getDiscountList: dispatch.discountListState.getDiscountList,
  getCatalogList: dispatch.catalogListState.getCatalogList,
  getCategoryList: dispatch.categoryListState.getCategoryList,
  getBrandList: dispatch.brandListState.getBrandList,
  getContractById: dispatch.contractState.getContractById,
  updateContract: dispatch.contractState.updateContract,
});

export const Contract = connect(mapState, mapDispatch)(ContractComponent);
