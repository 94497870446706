import {
  IGroupedWorkspace,
  IGroupedWorkspaceState,
  IWorkspace,
  IWorkspaceListItem,
  IWorkspaceListState,
  IWorkspacePosition,
  IWorkspacePositionState,
  IWorkspaceState,
} from 'entities/Workspace/Workspace.models';

export const workspaceListStateReducers = {
  setWorkspaceList: (state: IWorkspaceListState, payload: IWorkspaceListItem[]) => ({ ...state, data: payload }),
  setCount: (state: IWorkspaceListState, payload: number) => ({ ...state, count: payload }),
  setLoading: (state: IWorkspaceListState, payload: boolean) => ({ ...state, loading: payload }),
  addWorkspace: (state: IWorkspaceListState, payload: IWorkspace) => ({ ...state, data: [...state.data, payload] }),
  updateWorkspace: (state: IWorkspaceListState, payload: IWorkspace) => ({
    ...state,
    data: state.data.map((workspace) => (workspace.id === payload.id ? payload : workspace)),
  }),
  deleteWorkspace: (state: IWorkspaceListState, payload: IWorkspace) => ({
    ...state,
    data: state.data.filter((workspace) => workspace.id !== payload.id),
  }),
};

export const workspaceStateReducers = {
  setWorkspace: (state: IWorkspaceState, payload: IWorkspace | null) => ({ ...state, data: payload }),
  setLoading: (state: IWorkspaceState, payload: boolean) => ({ ...state, loading: payload }),
  setError: (state: IWorkspaceState, payload: string | null) => ({ ...state, error: payload }),
};

export const groupedWorkspaceStateReducers = {
  setGroupedWorkspace: (state: IGroupedWorkspaceState, payload: IGroupedWorkspace) => ({ ...state, data: payload }),
  setLoading: (state: IGroupedWorkspaceState, payload: boolean) => ({ ...state, loading: payload }),
  updateWorkspace: (state: IGroupedWorkspaceState, payload: IWorkspace) => {
    return { ...state, data: state.data ? { ...state.data, workspace: payload } : null };
  },
  addPosition: (state: IGroupedWorkspaceState, payload: IWorkspacePosition) => {
    return {
      ...state,
      data: state.data
        ? {
            ...state.data,
            categories: state.data.categories.map((category) => {
              if (category.categoryId === payload.categoryId) {
                return {
                  ...category,
                  positions: [...category.positions, payload],
                };
              }

              return category;
            }),
          }
        : null,
    };
  },
  updatePosition: (state: IGroupedWorkspaceState, payload: IWorkspacePosition) => {
    return {
      ...state,
      data: state.data
        ? {
            ...state.data,
            categories: state.data.categories.map((category) => {
              if (category.categoryId === payload.categoryId) {
                return {
                  ...category,
                  positions: category.positions.map((position) => (position.id === payload.id ? payload : position)),
                };
              }

              return category;
            }),
          }
        : null,
    };
  },
  deletePosition: (state: IGroupedWorkspaceState, payload: IWorkspacePosition) => {
    return {
      ...state,
      data: state.data
        ? {
            ...state.data,
            categories: state.data.categories
              .map((category) => {
                return { ...category, positions: category.positions.filter((position) => position.id !== payload.id) };
              })
              .filter((category) => !!category.positions.length),
          }
        : null,
    };
  },
};

export const workspacePositionStateReducers = {
  setPosition: (state: IWorkspacePositionState, payload: IWorkspacePosition | null) => ({ ...state, data: payload }),
  setLoading: (state: IWorkspacePositionState, payload: boolean) => ({ ...state, loading: payload }),
  setError: (state: IWorkspacePositionState, payload: string | null) => ({ ...state, error: payload }),
};
