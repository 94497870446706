import React, { FC, useState } from 'react';
import { Button, Checkbox, Drawer, InputNumber, Radio, Tooltip } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useLocation } from 'react-router-dom';
import { EPresetType } from 'common/const/preset.enum';
import { ITEMS_FOR_VIEW_LIMIT_8, RANGE_VALUE_MIN } from 'common/config';
import { numberToString, stringToNumber } from 'common/helpers/common.helper';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { ReactComponent as FiltersIcon } from 'app/assets/images/redesign/filters.svg';
import { IProperty } from 'entities/Property/Property.models';
import { getPropertyRangeResult, setPropertyListItemHidden } from 'entities/Property/Property.helper';

export const CatalogGoodsListFilter: FC = () => {
  const [openFilterCard, setOpenFilterCard] = useState<boolean>(false);
  const location = useLocation();
  const { propertiesForFilter, setFilterIsChanged, setPropertiesForFilter } = useCatalogContext();

  const toggleOpenFilterCard = () => {
    setOpenFilterCard((prev) => !prev);
    setFilterIsChanged(false);
  };

  const onPropertiesChange = (newProperties: IProperty[]) => {
    const mappedNewProperties = setPropertyListItemHidden(newProperties);

    setPropertiesForFilter(mappedNewProperties);
    setFilterIsChanged(true);
  };

  const onSinglePropertyChange = (id: number, value: string) => {
    const newProperties = propertiesForFilter.map((property) => {
      if (property.id === id) {
        return { ...property, result: [value] };
      }

      return property;
    });

    onPropertiesChange(newProperties);
  };

  const onMultiplePropertyChange = (id: number, value: CheckboxValueType[]) => {
    const newProperties = propertiesForFilter.map((property) => {
      if (property.id === id) {
        return { ...property, result: value as string[] };
      }

      return property;
    });

    onPropertiesChange(newProperties);
  };

  const onRangePropertyMinChange = (id: number, value: string | null) => {
    const newProperties = propertiesForFilter.map((property) => {
      if (property.id === id) {
        return {
          ...property,
          result: getPropertyRangeResult(value, property.result?.[1]),
        };
      }

      return property;
    });

    onPropertiesChange(newProperties);
  };

  const onRangePropertyMaxChange = (id: number, value: string | null) => {
    const newProperties = propertiesForFilter.map((property) => {
      if (property.id === id) {
        return {
          ...property,
          result: getPropertyRangeResult(property.result?.[0], value),
        };
      }

      return property;
    });

    onPropertiesChange(newProperties);
  };

  if (!propertiesForFilter.length) {
    return null;
  }

  return (
    <>
      <Tooltip title="Фильтры">
        <Button
          className={`button-circle filter ${location.search.length ? 'catalog-goods-list-filter__with-badge' : ''}`}
          icon={<FiltersIcon />}
          onClick={toggleOpenFilterCard}
        />
      </Tooltip>

      <Drawer
        rootClassName="redesign drawer catalog-goods-list-filter"
        open={openFilterCard}
        onClose={toggleOpenFilterCard}
        width={720}
      >
        <div className="drawer__title">Фильтр</div>

        {propertiesForFilter.map(({ id, displayName, unitOfMeasurement, presetType, values, result, hidden }) => {
          const title = displayName + (unitOfMeasurement ? `, ${unitOfMeasurement}` : '');
          const options = values.map(({ value }) => ({ label: value, value: value as string }));
          const numberValues: number[] = values
            .filter(({ value }) => !!value)
            .map(({ value }) => stringToNumber(value as string));
          const min = numberValues.length ? Math.min(...numberValues) : undefined;
          const max = numberValues.length ? Math.max(...numberValues) : undefined;

          if (hidden) {
            return null;
          }

          return (
            <div className="catalog-goods-list-filter__item" key={id}>
              <span className="text-h4 catalog-goods-list-filter__item-title">{title}</span>

              <div
                className={`${
                  presetType !== EPresetType.Range && values.length > ITEMS_FOR_VIEW_LIMIT_8
                    ? 'catalog-goods-list-filter__item-scrollable'
                    : ''
                }`}
              >
                <div className="catalog-goods-list-filter__item-scroll-container scrollbar-bordered">
                  {presetType === EPresetType.SingleValue && (
                    <Radio.Group
                      className="catalog-goods-list-filter__item-single"
                      options={options}
                      value={result?.[0]}
                      onChange={(e) => onSinglePropertyChange(id, e.target.value)}
                    />
                  )}

                  {(presetType === EPresetType.MultipleValue || presetType === EPresetType.Checkbox) && (
                    <Checkbox.Group
                      className="catalog-goods-list-filter__item-multiple"
                      options={options}
                      value={result}
                      onChange={(value) => onMultiplePropertyChange(id, value)}
                    />
                  )}

                  {presetType === EPresetType.Range && (
                    <div className="catalog-goods-list-filter__item-container">
                      <InputNumber
                        value={result?.[0]}
                        onChange={(value) => onRangePropertyMinChange(id, value)}
                        prefix="от"
                        placeholder={min ? numberToString(min) : undefined}
                        controls={false}
                        min={min ? numberToString(min) : RANGE_VALUE_MIN}
                        max={result?.[1] ? result[1] : max ? numberToString(max) : undefined}
                      />

                      <InputNumber
                        value={result?.[1]}
                        onChange={(value) => onRangePropertyMaxChange(id, value)}
                        prefix="до"
                        placeholder={max ? numberToString(max) : undefined}
                        controls={false}
                        min={result?.[0] ? result[0] : min ? numberToString(min) : RANGE_VALUE_MIN}
                        max={max ? numberToString(max) : undefined}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Drawer>
    </>
  );
};
