import axios from 'axios';
import {
  IUserCollectionDto,
  IUserListParams,
  IUser,
  IUserChangePasswordPayload,
  IUserDeletePayload,
  IUserRemoveSubdivisionPayload,
  IUserUpdateByAdminPayload,
  IUserUpdatePayload,
  IUserStatistics,
  IUserAutoSupplyApproveChangePayload,
  IUserInventoryStatistics,
} from 'entities/User/User.models';

const basePath = 'user';

export const userTransport = {
  getUserList: (params: IUserListParams): Promise<IUserCollectionDto> => axios.get(`${basePath}`, { params }),
  getUserById: (id: number): Promise<IUser> => axios.get(`${basePath}/${id}`),
  updateUserByAdmin: (payload: IUserUpdateByAdminPayload): Promise<IUser> => axios.patch(`${basePath}/by-admin`, payload),
  updateUser: (payload: IUserUpdatePayload): Promise<IUser> => axios.patch(`${basePath}`, payload),
  deleteUser: (payload: IUserDeletePayload): Promise<IUser> => axios.delete(`${basePath}`, { data: payload }),
  removeUserSubdivision: (payload: IUserRemoveSubdivisionPayload): Promise<IUser> =>
    axios.patch(`${basePath}/remove-subdivision`, payload),
  changeUserPassword: (payload: IUserChangePasswordPayload): Promise<IUser> =>
    axios.patch(`${basePath}/change-password`, payload),
  getUserStatistics: (): Promise<IUserStatistics> => axios.get(`${basePath}/stats`),
  getUserInventoryStatistics: (): Promise<IUserInventoryStatistics> => axios.get(`${basePath}/stats/inventory`),
  updateUserAutoSupplyApprove: (payload: IUserAutoSupplyApproveChangePayload): Promise<IUser> =>
    axios.patch(`${basePath}/set-auto-supply-approve`, payload),
};
