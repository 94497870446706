import React, { FC, ReactNode, useMemo } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { CountBadge } from 'common/components/CountBadge';
import { EInventoryListMenuTab } from 'common/const/inventory.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import {
  CHARACTER_LIMIT_MIN,
  DEFAULT_EMPTY_VALUE,
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_VALUE_0,
  LIST_LIMIT_0,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { RootDispatch, RootState } from 'app/store';
import { AllAvailableInventoryList } from 'entities/Inventory/components/AllAvailableInventoryList';
import { AllWriteOffInventoryActionListForUser } from 'entities/Inventory/components/AllWriteOffInventoryActionListForUser';
import { AllWriteOffInventoryList } from 'entities/Inventory/components/AllWriteOffInventoryList';
import { getInventoryListFilterOptions } from 'entities/Inventory/Inventory.helper';
import { IInventoryListPayload } from 'entities/Inventory/Inventory.models';
import { IUserInventoryStatistics } from 'entities/User/User.models';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  statistics: IUserInventoryStatistics;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    statistics,
    // state
    subdivisionList,
    userList,
    currentUser,
    // dispatch
    getUserList,
    getInventoryList,
  } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();
  const { setPage } = useInventoryContext();

  const { inventoryAvailableCount, inventoryWrittenOffCount, writeOffsCount } = statistics;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const tab = getSearchParam(ESearchParams.Tab);
  const isAllWriteOffInventoryActionListForUser = tab === EInventoryListMenuTab.WriteOffActionList;
  const isAllWriteOffInventoryList = tab === EInventoryListMenuTab.WriteOff;
  const subdivisionOptions = getInventoryListFilterOptions.subdivision(subdivisionList);
  const userOptions = getInventoryListFilterOptions.userWithNull(userList);

  const getMenuItems = () => {
    const items = [
      {
        key: EInventoryListMenuTab.Available,
        label: (
          <>
            Инструменты в наличии
            <CountBadge count={inventoryAvailableCount} />
          </>
        ),
      },
    ];

    if (!isAccountAdmin && writeOffsCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.WriteOffActionList,
        label: (
          <>
            Запросы о списании
            <CountBadge count={writeOffsCount} />
          </>
        ),
      });
    }

    if (inventoryWrittenOffCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.WriteOff,
        label: (
          <>
            Списанные инструменты
            <CountBadge count={inventoryWrittenOffCount} />
          </>
        ),
      });
    }

    return items;
  };

  const menu = useMemo(() => {
    return (
      <Menu
        items={getMenuItems()}
        selectedKeys={[tab === null ? EInventoryListMenuTab.Available : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab, statistics]);

  const renderFilters = (payload: IInventoryListPayload, setPayload: (value: IInventoryListPayload) => void) => {
    return (
      <div className="inventory-list__filters mb-12">
        {isAccountAdmin && (
          <>
            <Select
              rootClassName="redesign"
              className="inventory-list__filters-select"
              options={subdivisionOptions}
              value={payload?.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
              onChange={async (value: number) => {
                const newPayload = {
                  ...payload,
                  subdivisionIds: value !== DEFAULT_EMPTY_VALUE ? [value] : undefined,
                  userId: undefined,
                };

                setPage(DEFAULT_PAGINATION_PAGE);
                setPayload(newPayload);

                await getUserList({ subdivisionId: newPayload.subdivisionIds?.[0], limit: LIST_LIMIT_0 });
                await getInventoryList(newPayload);
              }}
              placeholder={EPlaceholder.SelectCompany}
              listHeight={SELECT_LIST_HEIGHT_320}
              virtual={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              className="inventory-list__filters-select"
              options={userOptions}
              value={payload?.userId !== undefined ? payload.userId : DEFAULT_EMPTY_VALUE}
              onChange={(value) => {
                const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

                setPage(DEFAULT_PAGINATION_PAGE);
                setPayload(newPayload);
                getInventoryList(newPayload);
              }}
              placeholder={EPlaceholder.SelectUser}
              listHeight={SELECT_LIST_HEIGHT_320}
              virtual={false}
              suffixIcon={<ArrowDownShortIcon />}
            />
          </>
        )}

        <Input
          style={{ flex: 1 }}
          placeholder={EPlaceholder.SearchByNameOrArticle}
          value={payload?.search}
          onChange={(e) => {
            const value = e.target.value;

            setPayload({ ...payload, search: value.trim().length >= CHARACTER_LIMIT_MIN ? value : undefined });
          }}
        />

        <Button
          className="button-circle primary"
          icon={<SearchIcon />}
          onClick={() => {
            setPage(DEFAULT_PAGINATION_PAGE);
            getInventoryList(payload);
          }}
        />
      </div>
    );
  };

  return isAllWriteOffInventoryList ? (
    <AllWriteOffInventoryList header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  ) : isAllWriteOffInventoryActionListForUser ? (
    <AllWriteOffInventoryActionListForUser header={header} navigation={navigation} menu={menu} statistics={statistics} />
  ) : (
    <AllAvailableInventoryList header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.subdivisionListState.data,
  userList: state.userListState.data,
  currentUser: state.userState.currentUser,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getInventoryList: dispatch.inventoryListState.getInventoryList,
});

export const AllInventoryList = connect(mapState, mapDispatch)(Component);
