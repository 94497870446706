import React, { FC } from 'react';
import { ReactComponent as PackageIcon } from 'app/assets/images/redesign/package.svg';

interface IComponentProps {
  count: number;
  limit?: number;
}

export const InventoryCount: FC<IComponentProps> = ({ count, limit }) => {
  return (
    <div className="inventory-count">
      <PackageIcon className="icon-package-bright-green" />

      {/* eslint-disable-next-line */}
      <span className="text-tag-accent">{`В наличии: ${count}${limit ? ` из ${limit}` : ''}`}</span>
    </div>
  );
};
