import React, { FC } from 'react';
import { IWorkspaceCategory, IWorkspacePosition } from 'entities/Workspace/Workspace.models';
import { WorkspacePositionCardMin } from 'entities/Workspace/components/WorkspacePositionCardMin';

interface IComponentProps {
  position: IWorkspacePosition;
  category?: IWorkspaceCategory;
}

export const WorkspaceSimilarPositions: FC<IComponentProps> = ({ position, category }) => {
  const similarPositions = category?.positions.filter((item) => item.id !== position.id);

  if (!similarPositions?.length) {
    return null;
  }

  return (
    <div className="mb-72">
      <div className="text-h4-item-name mb-8">Аналогичные позиции в карте</div>

      {similarPositions.map((position) => {
        return <WorkspacePositionCardMin key={position.id} position={position} />;
      })}
    </div>
  );
};
