/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { ERoute } from 'common/const/route.enum';
import { DEFAULT_VALUE_0, STATISTICS_OVERFLOW_COUNT } from 'common/config';
import { ReactComponent as CatalogIcon } from 'app/assets/images/redesign/catalog.svg';
import { ReactComponent as TreeIcon } from 'app/assets/images/redesign/tree-alt.svg';
import { ReactComponent as AccountIcon } from 'app/assets/images/redesign/account.svg';
import { ReactComponent as ContractIcon } from 'app/assets/images/redesign/contract.svg';
import { ReactComponent as DrawerIcon } from 'app/assets/images/redesign/drawer.svg';
import { ReactComponent as CartIcon } from 'app/assets/images/redesign/cart.svg';
import { ReactComponent as DrawerFullIcon } from 'app/assets/images/redesign/drawer-full.svg';
import { ReactComponent as ExitIcon } from 'app/assets/images/redesign/exit.svg';
import { ReactComponent as DocumentIcon } from 'app/assets/images/redesign/document.svg';
import { ReactComponent as ToolIcon } from 'app/assets/images/redesign/tool.svg';
import { IToken } from 'entities/Auth/Auth.models';
import { getUserInitials, getUserName, getUserRole } from 'entities/User/User.helper';
import { IUser, IUserStatistics } from 'entities/User/User.models';

export const getStatisticsItemCount = (count?: number) => {
  if (!count) {
    return DEFAULT_VALUE_0;
  }

  return count > STATISTICS_OVERFLOW_COUNT ? `${count}+` : count;
};

export const getSidebarItemClassName = ({ isActive }: { isActive: boolean }) =>
  `sidebar-item ${isActive ? 'sidebar-item__active' : ''}`;

export const getSidebarItems = (
  auth: IToken | null,
  currentUser: IUser | null,
  xBasketId: number | null,
  superAdminAsUser: boolean,
  statistics: IUserStatistics,
  onClick: () => void,
  logout: () => void,
) => {
  const { isSuperAdmin, isAccountAdmin, isSellerManager, isManager } = getUserRole(auth?.access.roles);
  const isSeller = auth?.access.isSeller;
  const firstName = currentUser?.firstName;
  const lastName = currentUser?.lastName;
  const initials = getUserInitials(firstName, lastName);
  const userName = getUserName(firstName, lastName);
  const workspaceId = currentUser?.workspaceId;

  return {
    body: {
      orangeGroup: [
        {
          key: 'catalog-list',
          show: isSuperAdmin,
          to: ERoute.CatalogList,
          icon: <CatalogIcon className="icon-catalog" />,
          label: 'Системные каталоги',
          onClick,
        },
        {
          key: 'catalog',
          show: !isSuperAdmin,
          to: window.location.pathname.includes(ERoute.Catalog) ? `${window.location.pathname}` : ERoute.Catalog,
          icon: <CatalogIcon className="icon-catalog" />,
          label: 'Каталог',
          onClick,
        },
        {
          key: 'workspace-list',
          show: !isSuperAdmin && !isSeller && isAccountAdmin,
          to: ERoute.WorkspaceList,
          icon: <DocumentIcon className="icon-document" />,
          label: 'Карты оснащения',
          onClick,
        },
        {
          key: 'workspace',
          show: !isSuperAdmin && !isSeller && !isAccountAdmin && workspaceId,
          to: ERoute.WorkspaceForUser,
          icon: <DocumentIcon className="icon-document" />,
          label: 'Карта оснащения',
          onClick,
        },
      ],
      darkGreenGroup: [
        {
          key: 'properties-settings',
          show: isSuperAdmin,
          to: ERoute.PropertiesSettings,
          icon: <TreeIcon className="icon-tree" />,
          label: 'Дерево характеристик',
          onClick,
        },
        {
          key: 'seller-need-list',
          show: !isSuperAdmin && isSellerManager,
          to: ERoute.RequestsForSeller,
          icon: <DrawerFullIcon className="icon-drawer-full" />,
          label: 'Заявки на поставку',
          badge: getStatisticsItemCount(statistics.sellerRequestsCount),
          onClick,
        },
        {
          key: 'manager-need-list',
          show: !isSuperAdmin && isManager,
          to: ERoute.RequestsForManager,
          icon: <DrawerFullIcon className="icon-drawer-full" />,
          label: 'Заявки на закупку',
          badge: getStatisticsItemCount(statistics.requestsCount),
          onClick,
        },
        {
          key: 'need-list',
          show: !isSuperAdmin && !isSellerManager,
          to: ERoute.RequestsForUser,
          icon: <DrawerIcon className="icon-drawer" />,
          label: 'Мои заявки',
          onClick,
        },
      ],
      brightGreenGroup: [
        {
          key: 'account-list',
          show: isSuperAdmin,
          to: ERoute.AccountList,
          icon: <AccountIcon className="icon-account" />,
          label: 'Аккаунты',
          onClick,
        },
        {
          key: 'contract-list',
          show: isSuperAdmin,
          to: ERoute.ContractList,
          icon: <ContractIcon className="icon-contact" />,
          label: 'Контракты',
          onClick,
        },
        {
          key: 'admin-contract-list',
          show: !isSuperAdmin && isAccountAdmin && isSeller,
          to: ERoute.AdminContractList,
          icon: <ContractIcon className="icon-contract" />,
          label: 'Контракты',
          onClick,
        },
        {
          key: 'active-basket-list',
          show: !isSuperAdmin && isSellerManager,
          to: ERoute.ActiveBasketList,
          icon: <CartIcon className="icon-cart" />,
          label: 'Корзины клиентов',
          end: xBasketId ? window.location.pathname.includes(`${ERoute.ActiveBasketList}/${xBasketId}`) : false,
          onClick,
        },
        {
          key: 'active-basket',
          show: !isSuperAdmin && isSellerManager && !!xBasketId,
          to: `${ERoute.ActiveBasketList}/${xBasketId}`,
          icon: <CartIcon className="icon-cart" />,
          label: 'Привязанная корзина',
          end: true,
          onClick,
        },
        {
          key: 'basket',
          show: !isSuperAdmin && !isSellerManager,
          to: ERoute.Basket,
          icon: <CartIcon className="icon-cart" />,
          label: 'Корзина',
          badge: getStatisticsItemCount(statistics.basketGoodsCount),
          onClick,
        },
        {
          key: 'inventory-list-for-user',
          show: !isSuperAdmin && !isSellerManager,
          to: ERoute.InventoryList,
          icon: <ToolIcon className="icon-tool" />,
          label: 'Учёт инструмента',
          onClick,
        },
      ],
    },
    footer: [
      {
        key: 'profile',
        show: !isSuperAdmin,
        to: ERoute.Profile,
        icon: (
          <span className="text-profile-icon color-dark-grey" style={{ zIndex: 1 }}>
            {initials}
          </span>
        ),
        label: userName,
        iconClassName: 'icon-profile',
        onClick,
      },
      {
        key: 'logout',
        show: true,
        to: '',
        icon: <ExitIcon className="icon-exit" />,
        label: superAdminAsUser ? 'Вернуться в аккаунт суперадминистратора' : 'Выйти',
        onClick: () => {
          onClick();
          logout();
        },
      },
    ],
  };
};

export const getSidebarHeaderSubtitle = (roles?: string[], subdivisionName?: string) => {
  const { isSuperAdmin, isAccountAdmin, isSellerManager, isManager } = getUserRole(roles);

  switch (true) {
    case !!subdivisionName: {
      return subdivisionName;
    }
    case isSuperAdmin: {
      return 'Суперадмин';
    }
    case isAccountAdmin: {
      return 'Администратор';
    }
    case isSellerManager: {
      return 'Менеджер';
    }
    case isManager: {
      return 'Менеджер по закупкам';
    }
    default: {
      return null;
    }
  }
};
