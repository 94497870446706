import React, { FC } from 'react';

interface IComponentProps {
  count?: string | number;
}

export const CountBadge: FC<IComponentProps> = ({ count }) => {
  if (!count) {
    return null;
  }

  return <span className="text-tag color-white count-badge">{count}</span>;
};
