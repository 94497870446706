import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Input, Modal, Spin, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ReactComponent as CopyIcon } from 'app/assets/images/copy.svg';
import { searchPropertyCategories } from 'entities/Categories/Categories.helper';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  treeData: DataNode[];
  searchValue: string;
  templateId?: number;
  changeSearchValue: (searchValue: string) => void;
  changeTemplateId: (categoryId: number) => void;
  onCancel: () => void;
  onSelect: () => void;
}

export const CategoryTemplateSearchModal: FC<IComponentProps> = (props) => {
  const { open, loading, treeData, searchValue, templateId, changeSearchValue, changeTemplateId, onCancel, onSelect } = props;

  const [data, setData] = useState<DataNode[]>([]);

  const onTemplateSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length) {
      const list = searchPropertyCategories(treeData, value);

      setData(list);
    } else {
      setData(treeData);
    }

    changeSearchValue(e.target.value);
  };

  useEffect(() => {
    if (!open) {
      setData(treeData);
    }
  }, [open]);

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false}>
      <Spin spinning={loading}>
        <div className="modal-gray__body category-template-search-modal__body">
          <Input value={searchValue} onChange={onTemplateSearch} placeholder="Поиск" prefix={<SearchIcon />} />

          <div className="category-template-search-modal__scroll-container">
            <Tree
              treeData={data}
              onSelect={(keys) => changeTemplateId(Number(keys[0]))}
              defaultExpandAll
              switcherIcon={<ChevronIcon />}
              selectedKeys={templateId ? [templateId] : undefined}
              titleRender={(node) => {
                return (
                  <span className="category-template-search-modal__item-name">
                    <span>{node.title as React.ReactNode}</span>

                    <Button
                      className="btn-icon"
                      icon={<CopyIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(`${node.title} #${node.key}#`);
                      }}
                    />
                  </span>
                );
              }}
            />
          </div>
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancel}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onSelect} disabled={!templateId}>
            Выбрать
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
