import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import {
  IWorkspaceListState,
  IWorkspaceState,
  IGroupedWorkspaceState,
  IWorkspacePositionState,
} from 'entities/Workspace/Workspace.models';
import {
  groupedWorkspaceStateReducers,
  workspaceListStateReducers,
  workspacePositionStateReducers,
  workspaceStateReducers,
} from 'entities/Workspace/Workspace.reducers';
import {
  groupedWorkspaceStateEffects,
  workspaceListStateEffects,
  workspacePositionStateEffects,
  workspaceStateEffects,
} from 'entities/Workspace/Workspace.effects';

export const workspaceListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as IWorkspaceListState,
  reducers: workspaceListStateReducers,
  effects: workspaceListStateEffects,
});

export const workspaceState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IWorkspaceState,
  reducers: workspaceStateReducers,
  effects: workspaceStateEffects,
});

export const groupedWorkspaceState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IGroupedWorkspaceState,
  reducers: groupedWorkspaceStateReducers,
  effects: groupedWorkspaceStateEffects,
});

export const workspacePositionState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IWorkspacePositionState,
  reducers: workspacePositionStateReducers,
  effects: workspacePositionStateEffects,
});
