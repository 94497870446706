import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ERequestsNavigationTab } from 'common/const/request.enum';
import { ERoute } from 'common/const/route.enum';
import { TabNavigation } from 'common/components/TabNavigation';
import { IncomingRequestsForSeller } from 'entities/Requests/components/IncomingRequestsForSeller';
import { SupplyListForSeller } from 'entities/Supply/components/SupplyListForSeller';

export const RequestsForSeller: FC = () => {
  const { tab } = useParams();

  const isSupplyList = tab === ERequestsNavigationTab.SupplyList;
  const header = <div className="text-body color-dark-grey need-list__subtitle">Заявки на закупку</div>;
  const navigation = useMemo(() => {
    return (
      <TabNavigation
        items={[
          { label: 'Входящие заказы', path: ERoute.RequestsForSeller },
          { label: 'Поставки', path: `${ERoute.RequestsForSeller}/${ERequestsNavigationTab.SupplyList}` },
        ]}
      />
    );
  }, []);

  return isSupplyList ? (
    <SupplyListForSeller header={header} navigation={navigation} />
  ) : (
    <IncomingRequestsForSeller header={header} navigation={navigation} />
  );
};
