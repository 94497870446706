/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { EEditPositionCardMode } from 'common/const/workspace.enum';

export interface IWorkspaceContext {
  openPositionCard: boolean;
  setOpenPositionCard: (value: boolean) => void;
  openEditPositionCard: boolean;
  setOpenEditPositionCard: (value: boolean) => void;
  editPositionCardMode: EEditPositionCardMode;
  setEditPositionCardMode: (value: EEditPositionCardMode) => void;
}

export const WorkspaceContext = createContext<IWorkspaceContext>({
  openPositionCard: false,
  setOpenPositionCard: () => {},
  openEditPositionCard: false,
  setOpenEditPositionCard: () => {},
  editPositionCardMode: EEditPositionCardMode.Edit,
  setEditPositionCardMode: () => {},
});

export const useWorkspaceContext = () => useContext(WorkspaceContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const WorkspaceProvider: FC<IComponentProps> = ({ children }) => {
  const [openPositionCard, setOpenPositionCard] = useState<boolean>(false);
  const [openEditPositionCard, setOpenEditPositionCard] = useState<boolean>(false);
  const [editPositionCardMode, setEditPositionCardMode] = useState<EEditPositionCardMode>(EEditPositionCardMode.Edit);

  return (
    <WorkspaceContext.Provider
      value={{
        openPositionCard,
        setOpenPositionCard,
        openEditPositionCard,
        setOpenEditPositionCard,
        editPositionCardMode,
        setEditPositionCardMode,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
