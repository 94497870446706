import React, { FC, ReactNode, useMemo } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { ESupplyListMenuTab } from 'common/const/supply.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { DEFAULT_EMPTY_VALUE, DEFAULT_PAGINATION_PAGE, SELECT_LIST_HEIGHT_320 } from 'common/config';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { RootDispatch, RootState } from 'app/store';
import { ActiveSupplyListForSeller } from 'entities/Supply/components/ActiveSupplyListForSeller';
import { CompletedSupplyListForSeller } from 'entities/Supply/components/CompletedSupplyListForSeller';
import { IGroupedSupplyListPayload } from 'entities/Supply/Supply.models';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    buyerList,
    subdivisionList,
    addressList,
    userList,
    // dispatch
    getSupplySubdivisionListForSeller,
    getSupplyAddressListForSeller,
    getSupplyUserListForSeller,
    setGroupedSupplyList,
    getGroupedSupplyList,
  } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();
  const { setPage, setSelectedSupplyList } = useSupplyContext();

  const buyerOptions = getNeedListFilterOptions.buyer(buyerList);
  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const addressOptions = getNeedListFilterOptions.address(addressList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);
  const isCompletedSupplyListPage = tab === ESupplyListMenuTab.Completed;
  const menu = useMemo(() => {
    return (
      <Menu
        items={[
          { key: ESupplyListMenuTab.Active, label: 'Активные поставки' },
          { key: ESupplyListMenuTab.Completed, label: 'Завершенные поставки' },
        ]}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Active : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab]);

  const renderFilters = (payload: IGroupedSupplyListPayload, setPayload: (value: IGroupedSupplyListPayload) => void) => {
    return (
      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={buyerOptions}
          value={payload?.accountId ? payload.accountId : DEFAULT_EMPTY_VALUE}
          onChange={async (value) => {
            const newPayload = {
              ...payload,
              accountId: value === DEFAULT_EMPTY_VALUE ? undefined : value,
              subdivisionIds: undefined,
              addressId: undefined,
              userId: undefined,
            };

            setPage(DEFAULT_PAGINATION_PAGE);
            setPayload(newPayload);
            setSelectedSupplyList([]);
            setGroupedSupplyList([]);

            if (value !== DEFAULT_EMPTY_VALUE) {
              await getSupplySubdivisionListForSeller({ statuses: newPayload.statuses, buyerId: value });
              await getSupplyAddressListForSeller({ statuses: newPayload.statuses, buyerId: value });
              await getSupplyUserListForSeller({ statuses: newPayload.statuses, buyerId: value });
            }

            await getGroupedSupplyList(newPayload);
          }}
          placeholder="Выберите компанию"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownShortIcon />}
        />

        {payload.accountId && (
          <>
            <Select
              rootClassName="redesign"
              className="need-list__filters-select"
              options={subdivisionOptions}
              value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
              onChange={(value) => {
                const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value] };

                setPage(DEFAULT_PAGINATION_PAGE);
                setSelectedSupplyList([]);
                setGroupedSupplyList([]);
                setPayload(newPayload);
                getGroupedSupplyList(newPayload);
              }}
              placeholder="Выберите подразделение"
              listHeight={SELECT_LIST_HEIGHT_320}
              virtual={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              className="need-list__filters-select"
              options={addressOptions}
              value={payload.addressId ? payload.addressId : DEFAULT_EMPTY_VALUE}
              onChange={(value) => {
                const newPayload = { ...payload, addressId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

                setPage(DEFAULT_PAGINATION_PAGE);
                setSelectedSupplyList([]);
                setGroupedSupplyList([]);
                setPayload(newPayload);
                getGroupedSupplyList(newPayload);
              }}
              placeholder="Выберите адрес отгрузки"
              listHeight={SELECT_LIST_HEIGHT_320}
              virtual={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              className="need-list__filters-select"
              options={userOptions}
              value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
              onChange={(value) => {
                const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

                setPage(DEFAULT_PAGINATION_PAGE);
                setSelectedSupplyList([]);
                setGroupedSupplyList([]);
                setPayload(newPayload);
                getGroupedSupplyList(newPayload);
              }}
              placeholder="Выберите пользователя"
              listHeight={SELECT_LIST_HEIGHT_320}
              virtual={false}
              suffixIcon={<ArrowDownShortIcon />}
            />
          </>
        )}

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload?.search}
          onChange={(e) => setPayload({ ...payload, search: e.target.value })}
        />

        <Button
          className="button-circle primary"
          icon={<SearchIcon />}
          onClick={() => {
            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedSupplyList([]);
            setGroupedSupplyList([]);
            getGroupedSupplyList(payload);
          }}
        />
      </div>
    );
  };

  return isCompletedSupplyListPage ? (
    <CompletedSupplyListForSeller header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  ) : (
    <ActiveSupplyListForSeller header={header} navigation={navigation} menu={menu} renderFilters={renderFilters} />
  );
};

const mapState = (state: RootState) => ({
  buyerList: state.buyerListState.data,
  subdivisionList: state.supplyListState.subdivisionList,
  addressList: state.supplyListState.addressList,
  userList: state.supplyListState.userList,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplySubdivisionListForSeller: dispatch.supplyListState.getSupplySubdivisionListForSeller,
  getSupplyAddressListForSeller: dispatch.supplyListState.getSupplyAddressListForSeller,
  getSupplyUserListForSeller: dispatch.supplyListState.getSupplyUserListForSeller,
  setGroupedSupplyList: dispatch.groupedSupplyListState.setGroupedSupplyList,
  getGroupedSupplyList: dispatch.groupedSupplyListState.getGroupedSupplyList,
});

export const SupplyListForSeller = connect(mapState, mapDispatch)(Component);
