import React from 'react';
import { Button, Dropdown } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Image } from 'common/components/Image';
import { InventoryCount } from 'common/components/InventoryCount';
import {
  EInventoryActionReasonLabel,
  EInventoryActionStatus,
  EInventoryActionType,
  EInventoryStatus,
} from 'common/const/inventory.enum';
import { mapToMenuWithDivider } from 'common/helpers/common.helper';
import { countFormatter, dateFormatter } from 'common/helpers/formatter.helper';
import { DEFAULT_EMPTY_VALUE, DEFAULT_VALUE_0 } from 'common/config';
import { IOptionGroup } from 'common/models';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as HistoryIcon } from 'app/assets/images/redesign/history.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { ReactComponent as ArrowRightLongIcon } from 'app/assets/images/redesign/arrow-right-long.svg';
import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';
import { getUserName } from 'entities/User/User.helper';
import { IUser } from 'entities/User/User.models';
import { IInventory, IInventoryAction, IInventoryActionShort } from 'entities/Inventory/Inventory.models';

export const getInventoryListFilterOptions = {
  subdivision: (list: ISubdivision[]) =>
    mapToMenuWithDivider([
      { label: 'Все подразделения', value: DEFAULT_EMPTY_VALUE },
      ...list.filter((subdivision) => !!subdivision).map((subdivision) => ({ label: subdivision.name, value: subdivision.id })),
    ]),
  user: (list: IUser[]) =>
    mapToMenuWithDivider([
      { label: 'Все пользователи', value: DEFAULT_EMPTY_VALUE },
      ...list.filter((user) => !!user).map((user) => ({ label: getUserName(user.firstName, user.lastName), value: user.id })),
    ]),
  userWithNull: (list: IUser[]) =>
    mapToMenuWithDivider([
      { label: 'Все пользователи', value: DEFAULT_EMPTY_VALUE },
      { label: 'Сотрудник не назначен', value: null },
      ...list.filter((user) => !!user).map((user) => ({ label: getUserName(user.firstName, user.lastName), value: user.id })),
    ]),
};

export const renderInventoryListRecords = (actions?: {
  isAvailable?: boolean;
  onTransferInventoryClick?: (id: number) => void;
  onWriteOffInventoryClick?: (id: number) => void;
}) => {
  const records = [
    {
      key: 'image',
      render: (_, { good: { image } }) => <Image size={60} src={image} />,
      width: 80,
    },
    {
      key: 'name',
      render: (_, record) => {
        const writeOffRequestCount = inventoryCount.writeOffRequestCount(record);
        const transferRequestCount = inventoryCount.transferRequestsCount(record);

        return (
          <>
            <div className="text-controls-accent mb-12">{record.good.name}</div>

            <div className="inventory-list__table-item-name-footer">
              {record.status === EInventoryStatus.Available ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserIcon className="icon-user-dark-grey" />

                  <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                    Ответственный: {getUserName(record.user?.firstName, record.user?.lastName)}
                  </span>
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />

                  <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                    Списан в количестве {countFormatter(record.count)}
                  </span>
                </div>
              )}

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <HistoryIcon className="icon-history-dark-grey" />

                <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                  Обновлено {dateFormatter.fullDateDotSeparator(record.updatedAt)}
                </span>
              </div>

              {!!transferRequestCount && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />

                  <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                    Запрос о передаче {countFormatter(transferRequestCount)}
                  </span>
                </div>
              )}

              {!!writeOffRequestCount && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />

                  <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                    Запрос о списании {countFormatter(writeOffRequestCount)}
                  </span>
                </div>
              )}

              {record.good.sellerCode && (
                <div className="text-tag-accent color-dark-grey" style={{ marginRight: 20 }}>
                  {record.good.sellerCode}
                </div>
              )}

              {record.good.buyerCode && <span className="text-tag-accent color-bright-green">{record.good.buyerCode}</span>}
            </div>
          </>
        );
      },
    },
    {
      key: 'count',
      render: (_, record) => {
        const availableCount = inventoryCount.withoutAllRequestsCount(record);

        return (
          <>
            <InventoryCount count={record.count} />

            <Dropdown
              overlayClassName="redesign"
              menu={{
                items: [
                  {
                    key: 'hand-over',
                    label: 'Передать инструмент',
                    onClick: ({ domEvent }) => {
                      domEvent.stopPropagation();
                      actions?.onTransferInventoryClick?.(record.id);
                    },
                    disabled: !availableCount,
                  },
                  { type: 'divider' },
                  {
                    key: 'send',
                    label: 'Отправить на списание',
                    onClick: ({ domEvent }) => {
                      domEvent.stopPropagation();
                      actions?.onWriteOffInventoryClick?.(record.id);
                    },
                    className: 'red',
                    disabled: !availableCount,
                  },
                ],
              }}
              placement="bottomRight"
            >
              <Button
                style={{ marginLeft: 16 }}
                className="button-icon"
                icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
              />
            </Dropdown>
          </>
        );
      },
      hidden: !actions?.isAvailable,
    },
    {
      key: 'empty',
      hidden: actions?.isAvailable,
    },
  ] as ColumnsType<IInventory>;

  // @ts-ignore
  return records.filter((record) => !record.hidden);
};

export const renderInventoryActionListRecords = (actions?: {
  isIncoming?: boolean;
  onAcceptRequestClick?: (record: IInventoryAction) => void;
  onCancelRequestClick?: (records: IInventoryAction[]) => void;
}) => {
  const records = [
    {
      key: 'image',
      render: (_, { good: { image } }) => <Image size={60} src={image} />,
      width: 80,
    },
    {
      key: 'name',
      render: (_, record) => {
        const isWriteOffInventoryAction = record.type === EInventoryActionType.WriteOff;

        return (
          <>
            <div className="text-controls-accent mb-12">{record.good.name}</div>

            <div className="inventory-list__table-item-name-footer">
              {record.user && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserIcon className="icon-user-dark-grey" />

                  <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                    Ответственный: {getUserName(record.user?.firstName, record.user?.lastName)}
                  </span>
                </div>
              )}

              {isWriteOffInventoryAction ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />

                  <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                    Запрос о списании {countFormatter(record.count)}
                  </span>
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <HistoryIcon className="icon-history-dark-grey" />

                  <span className="text-tag-accent color-dark-grey" style={{ marginLeft: 8 }}>
                    Запрос о передаче {countFormatter(record.count)}
                  </span>
                </div>
              )}

              {record.good.sellerCode && (
                <div className="text-tag-accent color-dark-grey" style={{ marginRight: 20 }}>
                  {record.good.sellerCode}
                </div>
              )}

              {record.good.buyerCode && <span className="text-tag-accent color-bright-green">{record.good.buyerCode}</span>}
            </div>
          </>
        );
      },
    },
    {
      key: 'actions',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              className="button-s success"
              onClick={(e) => {
                e.stopPropagation();
                actions?.onAcceptRequestClick?.(record);
              }}
            >
              Принять запрос
            </Button>

            <Button
              className="button-s secondary"
              onClick={(e) => {
                e.stopPropagation();
                actions?.onCancelRequestClick?.([record]);
              }}
            >
              Отклонить
            </Button>
          </div>
        );
      },
      width: 294,
      hidden: !actions?.isIncoming,
    },
    {
      key: 'actions',
      render: (_, record) => {
        return (
          <Button
            className="button-s secondary"
            onClick={(e) => {
              e.stopPropagation();
              actions?.onCancelRequestClick?.([record]);
            }}
          >
            Отменить запрос
          </Button>
        );
      },
      width: 178,
      hidden: actions?.isIncoming,
    },
  ] as ColumnsType<IInventoryAction>;

  // @ts-ignore
  return records.filter((record) => !record.hidden);
};

export const groupInventoryTransferCardUserOptions = (userList: IUser[]) => {
  return userList.reduce((acc: IOptionGroup[], item) => {
    const existingGroup = acc.find((group) => item.subdivision && group.label === item.subdivision.name);

    if (existingGroup) {
      existingGroup.options.push({ label: getUserName(item.firstName, item.lastName), value: item.id });
    } else {
      if (item.subdivision) {
        acc.push({
          label: item.subdivision.name,
          options: [{ label: getUserName(item.firstName, item.lastName), value: item.id }],
        });
      }
    }

    return acc;
  }, []);
};

export const filterInventoryTransferCardUserOptions = (options: IOptionGroup[], search: string) => {
  return options
    .map((item) => ({
      ...item,
      options: item.options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase())),
    }))
    .filter((item) => !!item.options.length);
};

export const inventoryCount = {
  totalCount: (inventory: IInventory | null) => {
    if (!inventory) {
      return DEFAULT_VALUE_0;
    }

    return inventory.count;
  },
  transferRequestsCount: (inventory: IInventory | null) => {
    if (!inventory) {
      return DEFAULT_VALUE_0;
    }

    return inventory.actions.reduce((acc, action) => {
      if (action.status === EInventoryActionStatus.New && action.type === EInventoryActionType.Transfer) {
        return acc + action.count;
      }

      return acc;
    }, 0);
  },
  writeOffRequestCount: (inventory: IInventory | null) => {
    if (!inventory) {
      return DEFAULT_VALUE_0;
    }

    return inventory.actions.reduce((acc, action) => {
      if (action.status === EInventoryActionStatus.New && action.type === EInventoryActionType.WriteOff) {
        return acc + action.count;
      }

      return acc;
    }, 0);
  },
  withoutTransferRequestsCount: (inventory: IInventory | null) => {
    if (!inventory) {
      return DEFAULT_VALUE_0;
    }

    const transferRequestsCount = inventory.actions.reduce((acc, action) => {
      if (action.status === EInventoryActionStatus.New && action.type === EInventoryActionType.Transfer) {
        return acc + action.count;
      }

      return acc;
    }, 0);

    return inventory.count - transferRequestsCount;
  },
  withoutWriteOffRequestsCount: (inventory: IInventory | null) => {
    if (!inventory) {
      return DEFAULT_VALUE_0;
    }

    const writeOffRequestCount = inventory.actions.reduce((acc, action) => {
      if (action.status === EInventoryActionStatus.New && action.type === EInventoryActionType.WriteOff) {
        return acc + action.count;
      }

      return acc;
    }, 0);

    return inventory.count - writeOffRequestCount;
  },
  withoutAllRequestsCount: (inventory: IInventory | null) => {
    if (!inventory) {
      return DEFAULT_VALUE_0;
    }

    const newRequestCount = inventory.actions.reduce((acc, action) => {
      if (action.status === EInventoryActionStatus.New) {
        return acc + action.count;
      }

      return acc;
    }, 0);

    return inventory.count - newRequestCount;
  },
};

export const mapInventoryActionToInventoryActionShort = (action: IInventoryAction) => {
  return {
    id: action.id,
    createdAt: action.createdAt,
    updatedAt: action.updatedAt,
    count: action.count,
    type: action.type,
    status: action.status,
    good: action.good,
  };
};

export const getInventoryCardNotificationMessage = (inventory: IInventory, action: IInventoryActionShort) => {
  let message = '';

  if (inventory.user) {
    message += `Отправитель: ${getUserName(inventory.user.firstName, inventory.user.lastName)}. `;
  }

  if (action.type === EInventoryActionType.Transfer) {
    if (action.receiver) {
      message += `Новый ответственный: ${getUserName(action.receiver.firstName, action.receiver.lastName)}. `;
    }

    message += `Количество оборудования: ${countFormatter(action.count)}`;
  } else {
    if (action.reason) {
      message += `Причина: «${EInventoryActionReasonLabel[action.reason]}». `;
    }

    if (action.comment) {
      message += `Комментарий: «${action.comment}»`;
    }
  }

  return message;
};
