import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Popover, Switch } from 'antd';
import { connect } from 'react-redux';
import { TabNavigation } from 'common/components/TabNavigation';
import { ERequestsNavigationTab } from 'common/const/request.enum';
import { ERoute } from 'common/const/route.enum';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SettingsIcon } from 'app/assets/images/redesign/settings.svg';
import { IncomingRequestsForManager } from 'entities/Requests/components/IncomingRequestsForManager';
import { SupplyListForManager } from 'entities/Supply/components/SupplyListForManager';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ currentUser, setUserAutoSupplyApprove, updateUserAutoSupplyApprove }) => {
  const { tab } = useParams();

  const isSupplyList = tab === ERequestsNavigationTab.SupplyList;
  const header = useMemo(() => {
    return (
      <>
        <div className="need-list-header">
          <Popover
            overlayStyle={{ width: 360 }}
            overlayClassName="redesign"
            placement="bottomRight"
            content={
              <div className="need-list-header__settings-popover">
                <span className="text-h4 mb-6">Автоматическое подтверждение поставок</span>

                <span className="text-tag color-dark-grey">
                  Когда включена эта функция, все поставки, кроме тех, где поставщик изменил цену, подтверждаются автоматически.
                </span>

                <div className="divider" style={{ margin: '12px 0' }} />

                <div className="need-list-header__settings-popover-footer">
                  <span className="text-controls">Подтверждать автоматически</span>

                  <Switch
                    checked={currentUser?.autoSupplyApprove}
                    onChange={(checked) => {
                      setUserAutoSupplyApprove(checked);
                      updateUserAutoSupplyApprove({ autoSupplyApprove: checked });
                    }}
                  />
                </div>
              </div>
            }
            trigger="click"
          >
            <Button className="button-circle secondary" icon={<SettingsIcon />} />
          </Popover>
        </div>

        <div className="text-body color-dark-grey need-list__subtitle">Заявки на закупку</div>
      </>
    );
  }, [currentUser]);
  const navigation = useMemo(() => {
    return (
      <TabNavigation
        items={[
          { label: 'Входящие заявки', path: ERoute.RequestsForManager },
          { label: 'Поставки', path: `${ERoute.RequestsForManager}/${ERequestsNavigationTab.SupplyList}` },
        ]}
      />
    );
  }, []);

  return isSupplyList ? (
    <SupplyListForManager header={header} navigation={navigation} />
  ) : (
    <IncomingRequestsForManager header={header} navigation={navigation} />
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setUserAutoSupplyApprove: dispatch.userState.setUserAutoSupplyApprove,
  updateUserAutoSupplyApprove: dispatch.userState.updateUserAutoSupplyApprove,
});

export const RequestsForManager = connect(mapState, mapDispatch)(Component);
