import React, { FC } from 'react';
import { ReactComponent as PackageIcon } from 'app/assets/images/redesign/package.svg';

interface IComponentProps {
  content: string;
}

export const WorkspacePositionInventoryLimit: FC<IComponentProps> = ({ content }) => {
  return (
    <div className="workspace-position-inventory-limit">
      <PackageIcon className="icon-package-dark-grey" />

      <span className="text-tag-accent color-dark-grey">{content}</span>
    </div>
  );
};
