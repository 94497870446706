import React, { FC, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EInventoryStatus } from 'common/const/inventory.enum';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import { DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_0, LIST_LIMIT_20 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { InventoryCard } from 'entities/Inventory/components/InventoryCard';
import { InventoryEmptyList } from 'entities/Inventory/components/InventoryEmptyList';
import { IInventoryListPayload } from 'entities/Inventory/Inventory.models';
import { renderInventoryListRecords } from 'entities/Inventory/Inventory.helper';
import { InventoryTransferCard } from 'entities/Inventory/components/InventoryTransferCard';
import { InventoryWriteOffCard } from 'entities/Inventory/components/InventoryWriteOffCard';
import { GoodsCard } from 'entities/Goods/components/GoodsCard';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  menu: ReactNode;
  renderFilters: (value: IInventoryListPayload, setPayload: (value: IInventoryListPayload) => void) => ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    menu,
    renderFilters,
    // state
    inventoryList,
    inventoryListCount,
    inventoryListLoading,
    userListLoading,
    inventoryLoading,
    currentUser,
    userInventoryStatisticsLoading,
    // dispatch
    getSubdivisionList,
    getUserList,
    setInventoryList,
    getInventoryList,
    getInventoryListPart,
    getInventoryById,
    getInventoryUserList,
    getUserInventoryStatistics,
  } = props;

  const userId = currentUser?.id;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);

  const [payload, setPayload] = useState<IInventoryListPayload>({
    statuses: [EInventoryStatus.Available],
    limit: LIST_LIMIT_20,
    offset: DEFAULT_LIST_OFFSET,
    userId: !isAccountAdmin ? userId : undefined,
  });
  const {
    page,
    setPage,
    fetchLoading,
    setFetchLoading,
    isNestedCard,
    setOpenInventoryCard,
    setOpenInventoryTransferCard,
    setOpenInventoryWriteOffCard,
  } = useInventoryContext();

  const listLoading =
    userListLoading || inventoryListLoading || inventoryLoading || userInventoryStatisticsLoading || fetchLoading;

  const onTransferInventoryClick = async (id: number) => {
    if (!isNestedCard) {
      await getInventoryById({ id });
    }

    await getInventoryUserList({ limit: LIST_LIMIT_0 });
    setOpenInventoryTransferCard(true);
  };

  const onWriteOffInventoryClick = async (id: number) => {
    if (!isNestedCard) {
      await getInventoryById({ id });
    }

    setOpenInventoryWriteOffCard(true);
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setInventoryList([]);
      await getUserInventoryStatistics();

      if (isAccountAdmin) {
        await getSubdivisionList({ limit: LIST_LIMIT_0 });
        await getUserList({ limit: LIST_LIMIT_0 });
      }

      await getInventoryList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!inventoryListLoading && inventoryList.length < inventoryListCount}
        scrollToTopTrigger={[payload]}
        onLoad={() => {
          setPage(page + 1);
          getInventoryListPart({ ...payload, offset: LIST_LIMIT_20 * page });
        }}
      >
        {header}
        {navigation}
        {menu}
        {renderFilters(payload, setPayload)}

        <Table
          className={`
                table-hover inventory-list__table
                ${!!inventoryList.length && inventoryList.length === inventoryListCount && 'inventory-list__table-end-of-list'}
                ${!inventoryList.length && 'inventory-list__table-empty'}
              `}
          dataSource={inventoryList.map((inventory) => ({ ...inventory, key: inventory.id }))}
          columns={renderInventoryListRecords({ isAvailable: true, onTransferInventoryClick, onWriteOffInventoryClick })}
          onRow={({ id }) => ({ onClick: () => getInventoryById({ id, onSuccess: () => setOpenInventoryCard(true) }) })}
          showHeader={false}
          pagination={false}
          locale={{
            emptyText: <InventoryEmptyList open={!(inventoryListLoading || fetchLoading) && !inventoryList.length} />,
          }}
          loading={{ spinning: listLoading, indicator: <SpinIndicator /> }}
        />
      </InfiniteScrollContainer>

      <InventoryCard />

      <InventoryTransferCard />

      <InventoryWriteOffCard />

      <GoodsCard />
    </>
  );
};

const mapState = (state: RootState) => ({
  inventoryList: state.inventoryListState.data,
  inventoryListCount: state.inventoryListState.count,
  inventoryListLoading: state.inventoryListState.loading,
  userListLoading: state.userListState.loading,
  inventoryLoading: state.inventoryState.loading,
  currentUser: state.userState.currentUser,
  userInventoryStatisticsLoading: state.userInventoryStatisticsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  getUserList: dispatch.userListState.getUserList,
  setInventoryList: dispatch.inventoryListState.setInventoryList,
  getInventoryList: dispatch.inventoryListState.getInventoryList,
  getInventoryListPart: dispatch.inventoryListState.getInventoryListPart,
  getInventoryById: dispatch.inventoryState.getInventoryById,
  getInventoryUserList: dispatch.inventoryState.getInventoryUserList,
  getUserInventoryStatistics: dispatch.userInventoryStatisticsState.getUserInventoryStatistics,
});

export const AllAvailableInventoryList = connect(mapState, mapDispatch)(Component);
