import React, { ChangeEvent } from 'react';
import { Button, Input, Modal, Spin, Table } from 'antd';
import { toDataSourceMapper } from 'common/helpers/common.helper';
import { EMessage } from 'common/const/message.enum';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { renderWorkspaceCollectionRecords } from 'entities/Modal/Modal.helper';
import { IWorkspaceListItem } from 'entities/Workspace/Workspace.models';

interface IComponentProps {
  open: boolean;
  searchValue: string;
  workspaces?: IWorkspaceListItem[];
  workspaceId?: number;
  updateLoading: boolean;
  searchLoading: boolean;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (workspaceId: number) => void;
  onConfirmClick: () => void;
  onCancelClick: () => void;
  onAddWorkspaceClick: () => void;
}

export const SelectWorkspaceModal: React.FC<IComponentProps> = (props) => {
  const {
    open,
    searchValue,
    workspaces,
    workspaceId,
    updateLoading,
    searchLoading,
    onSearch,
    onSelect,
    onConfirmClick,
    onCancelClick,
    onAddWorkspaceClick,
  } = props;

  const dataSource = toDataSourceMapper<IWorkspaceListItem>(workspaces);
  const columns = renderWorkspaceCollectionRecords();

  return (
    <Modal className="modal select-workspace-modal" open={open} footer={false} closeIcon={false} width={391}>
      <Spin spinning={updateLoading}>
        <div className="modal-content">
          <Input
            value={searchValue}
            onChange={onSearch}
            placeholder="Введите название Карты рабочего места"
            suffix={<SearchIcon />}
          />

          <Table
            size="small"
            className="select-workspace-modal__table"
            rowClassName={(record) => `select-workspace-modal__table_item ${record.id === workspaceId ? 'selected' : ''}`}
            dataSource={dataSource}
            columns={columns}
            showHeader={false}
            scroll={{ y: 176 }}
            loading={searchLoading}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => onSelect(record.id),
              };
            }}
            locale={{ emptyText: EMessage.WorkspaceListIsEmpty }}
          />

          <div className="modal-footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отменить
            </Button>

            {dataSource?.length ? (
              <Button className="btn btn-default" onClick={onConfirmClick} disabled={!workspaceId}>
                Подтвердить
              </Button>
            ) : (
              <Button className="btn btn-default" onClick={onAddWorkspaceClick}>
                Добавить карту рабочего места
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
