import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { getSidebarItemClassName } from 'common/helpers/sidebar.helper';
import { CountBadge } from 'common/components/CountBadge';

interface IComponentProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  end?: boolean;
  badge?: number | string;
  iconClassName?: string;
  onClick?: () => void;
}

export const SidebarItem: FC<IComponentProps> = ({ to, icon, label, end, badge, iconClassName, onClick }) => {
  return (
    <NavLink to={to} className={getSidebarItemClassName} onClick={onClick} end={end}>
      <div className="sidebar-item__content">
        <div className={`sidebar-item__icon ${iconClassName} ${badge ? 'sidebar-item__icon-badge' : ''}`}>{icon}</div>

        <div className="sidebar-item__label">
          <span className="text-h4">{label}</span>
        </div>

        <CountBadge count={badge} />
      </div>
    </NavLink>
  );
};
