import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Spin, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NameLabel } from 'common/components/NameLabel';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { EFormFieldMessage } from 'common/const/form.enum';
import { ERoute } from 'common/const/route.enum';
import { debounce } from 'common/helpers/common.helper';
import { checkForFormErrors, rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { normalizePhoneNumber, phoneFormatter } from 'common/helpers/formatter.helper';
import { CHARACTER_LIMIT_MIN, PHONE_PATTERN } from 'common/config';
import { IFormValues } from 'common/models';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as DocumentIcon } from 'app/assets/images/redesign/document.svg';
import { RootDispatch, RootState } from 'app/store';
import { getUserName, getUserRole } from 'entities/User/User.helper';
import { IUserChangePasswordPayload } from 'entities/User/User.models';
import { ChangePasswordCard } from 'entities/User/components/ChangePasswordCard';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    currentUser,
    userLoading,
    userError,
    // dispatch
    setUserError,
    updateUser,
    updateUserAutoSupplyApprove,
    changeUserPassword,
  } = props;

  const [autoSupplyApprove, setAutoSupplyApprove] = useState<boolean>(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(false);
  const [openChangePasswordCard, setOpenChangePasswordCard] = useState<boolean>(false);
  const [form] = useForm();

  const { isSellerManager, isManager } = getUserRole(currentUser?.roles);

  const handleUpdateUser = (value: IFormValues) => {
    const { firstName, lastName, phone } = value;
    const formHasErrors = checkForFormErrors(form);

    if (currentUser && !formHasErrors) {
      updateUser({
        id: currentUser.id,
        firstName,
        lastName,
        phone: phone ? normalizePhoneNumber(phone) : undefined,
      });
    }
  };

  const onAutoSupplyApproveChange = (value: boolean) => {
    setAutoSupplyApprove(value);
    updateUserAutoSupplyApprove({ autoSupplyApprove: value });
  };

  const onNotificationsEnabledChange = (value: boolean) => {
    setNotificationsEnabled(value);

    if (currentUser) {
      updateUser({ id: currentUser.id, notificationsEnabled: value });
    }
  };

  const onPasswordChange = (value: IUserChangePasswordPayload) => {
    setUserError(null);
    changeUserPassword({ ...value, onSuccess: () => showSuccessMessage('Пароль для входа успешно изменен.') });
  };

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        phone: phoneFormatter(currentUser.phone),
      });
      setAutoSupplyApprove(currentUser.autoSupplyApprove);
      setNotificationsEnabled(currentUser.notificationsEnabled);
    }
  }, [currentUser]);

  return (
    <Spin spinning={userLoading} indicator={<SpinIndicator />} wrapperClassName="profile__spin">
      <div className="redesign profile">
        <div className="profile__content">
          <div className="mb-20 text-body color-dark-grey">Личный кабинет</div>

          <div className="text-h1 mb-32">{getUserName(currentUser?.firstName, currentUser?.lastName)}</div>

          {(currentUser?.subdivisionId || currentUser?.workspaceId) && (
            <div className="profile__details">
              {currentUser?.subdivision && (
                <NameLabel icon={<AffiliateIcon className="icon-affiliate-bright-green" />} name={currentUser.subdivision.name} />
              )}

              {currentUser?.workspace && (
                <Link to={ERoute.WorkspaceForUser}>
                  <NameLabel
                    icon={<DocumentIcon className="icon-document-bright-green" />}
                    name={currentUser.workspace.name}
                    iconClassName="document"
                  />
                </Link>
              )}
            </div>
          )}

          <Form layout="vertical" form={form} onValuesChange={debounce(handleUpdateUser)} requiredMark={false}>
            <div className="profile__block">
              <div className="text-h3 profile__block-title">Персональные данные</div>

              <div className="profile__block-container">
                <Form.Item
                  className="profile__block-field"
                  name="lastName"
                  label="Фамилия"
                  rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
                >
                  <Input placeholder={EPlaceholder.EnterLastName} />
                </Form.Item>

                <Form.Item
                  className="profile__block-field"
                  name="firstName"
                  label="Имя"
                  rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
                >
                  <Input placeholder={EPlaceholder.EnterFirstName} />
                </Form.Item>
              </div>
            </div>

            <div className="profile__block">
              <div className="text-h3 profile__block-title">Контактные данные</div>

              <div className="profile__block-container">
                <Form.Item
                  className="profile__block-field"
                  name="email"
                  label="Электронная почта"
                  initialValue={currentUser?.email}
                >
                  <Input placeholder={EPlaceholder.EnterEmail} disabled />
                </Form.Item>

                <Form.Item
                  className="profile__block-field"
                  name="phone"
                  label="Телефон"
                  normalize={phoneFormatter}
                  rules={[
                    rules.required(),
                    {
                      pattern: PHONE_PATTERN,
                      message: EFormFieldMessage.InvalidPhoneFormat,
                    },
                  ]}
                >
                  <Input placeholder={EPlaceholder.EnterPhone} />
                </Form.Item>
              </div>
            </div>
          </Form>

          <div className="profile__block">
            <div className="text-h3 profile__block-title">Пароль</div>

            <Button className="button-s primary" onClick={() => setOpenChangePasswordCard(true)}>
              Изменить пароль
            </Button>
          </div>
        </div>

        {(isManager || isSellerManager) && (
          <div className="profile__settings">
            <div className="text-h1-drawers mb-52">Настройки</div>

            <div className="profile__settings-container">
              <div className="text-h3">Уведомления на электронную почту</div>

              <div className="profile__settings-block">
                <div className="profile__settings-block-header">
                  <span className="text-body">Получать уведомления</span>

                  <Switch checked={notificationsEnabled} onChange={onNotificationsEnabledChange} />
                </div>

                <span className="text-tag color-dark-grey">
                  Ежедневная рассылка о новых поступивших заявках и новых поставках, требующих подтверждения
                </span>
              </div>
            </div>

            {isManager && (
              <div className="profile__settings-container">
                <div className="text-h3">Подтверждение поставок</div>

                <div className="profile__settings-block">
                  <div className="profile__settings-block-header">
                    <span className="text-body">Подтверждать автоматически</span>

                    <Switch checked={autoSupplyApprove} onChange={onAutoSupplyApproveChange} />
                  </div>

                  <span className="text-tag color-dark-grey">
                    Когда включена эта функция, все поставки, кроме тех, где поставщик изменил цену, подтверждаются автоматически.
                  </span>
                </div>
              </div>
            )}
          </div>
        )}

        <ChangePasswordCard
          open={openChangePasswordCard}
          error={userError}
          loading={userLoading}
          onClose={() => setOpenChangePasswordCard(false)}
          onConfirm={onPasswordChange}
        />
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
  userLoading: state.userState.loading,
  userError: state.userState.error,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setUserError: dispatch.userState.setUserError,
  updateUser: dispatch.userState.updateUser,
  updateUserAutoSupplyApprove: dispatch.userState.updateUserAutoSupplyApprove,
  changeUserPassword: dispatch.userState.changeUserPassword,
});

export const Profile = connect(mapState, mapDispatch)(Component);
