/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { DEFAULT_PAGINATION_PAGE } from 'common/config';
import { IInventoryAction } from 'entities/Inventory/Inventory.models';

export interface IInventoryContext {
  page: number;
  setPage: (value: number) => void;
  fetchLoading: boolean;
  setFetchLoading: (value: boolean) => void;
  isNestedCard: boolean;
  setIsNestedCard: (value: boolean) => void;
  openInventoryActionCard: boolean;
  setOpenInventoryCard: (value: boolean) => void;
  openInventoryCard: boolean;
  setOpenInventoryActionCard: (value: boolean) => void;
  openInventoryTransferCard: boolean;
  setOpenInventoryTransferCard: (value: boolean) => void;
  openInventoryWriteOffCard: boolean;
  setOpenInventoryWriteOffCard: (value: boolean) => void;
  selectedInventoryActionList: IInventoryAction[];
  setSelectedInventoryActionList: (value: IInventoryAction[]) => void;
  selectedInventoryActionListIds: number[];
}

export const InventoryContext = createContext<IInventoryContext>({
  page: DEFAULT_PAGINATION_PAGE,
  setPage: () => {},
  fetchLoading: false,
  setFetchLoading: () => {},
  isNestedCard: false,
  setIsNestedCard: () => {},
  openInventoryCard: false,
  setOpenInventoryCard: () => {},
  openInventoryActionCard: false,
  setOpenInventoryActionCard: () => {},
  openInventoryTransferCard: false,
  setOpenInventoryTransferCard: () => {},
  openInventoryWriteOffCard: false,
  setOpenInventoryWriteOffCard: () => {},
  selectedInventoryActionList: [],
  setSelectedInventoryActionList: () => {},
  selectedInventoryActionListIds: [],
});

export const useInventoryContext = () => useContext(InventoryContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const InventoryProvider: FC<IComponentProps> = ({ children }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [isNestedCard, setIsNestedCard] = useState<boolean>(false);
  const [openInventoryCard, setOpenInventoryCard] = useState<boolean>(false);
  const [openInventoryActionCard, setOpenInventoryActionCard] = useState<boolean>(false);
  const [openInventoryTransferCard, setOpenInventoryTransferCard] = useState<boolean>(false);
  const [openInventoryWriteOffCard, setOpenInventoryWriteOffCard] = useState<boolean>(false);
  const [selectedInventoryActionList, setSelectedInventoryActionList] = useState<IInventoryAction[]>([]);

  const selectedInventoryActionListIds = selectedInventoryActionList.map((action) => action.id);

  return (
    <InventoryContext.Provider
      value={{
        page,
        setPage,
        fetchLoading,
        setFetchLoading,
        isNestedCard,
        setIsNestedCard,
        openInventoryCard,
        setOpenInventoryCard,
        openInventoryActionCard,
        setOpenInventoryActionCard,
        openInventoryTransferCard,
        setOpenInventoryTransferCard,
        openInventoryWriteOffCard,
        setOpenInventoryWriteOffCard,
        selectedInventoryActionList,
        setSelectedInventoryActionList,
        selectedInventoryActionListIds,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
