import React, { FC } from 'react';
import { Image as AntdImage } from 'antd';
import placeholder from 'app/assets/images/redesign/placeholder.svg';

interface IComponentProps {
  size: number;
  src?: string;
  className?: string;
}

export const Image: FC<IComponentProps> = ({ size, src, className }) => (
  <AntdImage wrapperClassName={`redesign image-${size} ${className}`} src={src ? src : placeholder} preview={false} />
);
