export const envConfig = (process.env.NODE_ENV === 'development' ? process.env : (window as any).env) as {
  SENTRY_DSN: string;
  NODE_ENV: string;
};

export const PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,}$/;
export const PHONE_PATTERN = /^\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
export const PRICE_PATTERN = /\B(?=(\d{3})+(?!\d))/g;
export const INTEGER_PATTERN = /^[0-9]+$/;
export const ESCAPE_REGEX_PATTERN = /[.*+?^${}()|[\]\\]/g;
export const NUMBER_PATTERN = /^[0-9\b]+$/;

export const DEFAULT_DEBOUNCE_DELAY = 800;
export const COUNT_CHANGE_DELAY = 200;

export const PROPERTY_CASCADER_CHARACTER_LIMIT_MIN = 1;
export const PROPERTY_CASCADER_ITEM_PROPERTY_OFFSET = 50;

export const LEGAL_INN_LENGTH = 10;
export const LEGAL_KPP_LENGTH = 9;

export const LIST_LIMIT_20 = 20;
export const LIST_LIMIT_10 = 10;
export const LIST_LIMIT_5 = 5;
export const LIST_LIMIT_0 = 0;
export const LIST_LIMIT_FOR_NEEDS = 100;

export const ITEMS_FOR_VIEW_LIMIT_11 = 11;
export const ITEMS_FOR_VIEW_LIMIT_8 = 8;
export const ITEMS_FOR_VIEW_LIMIT_4 = 4;

export const DRAWER_Z_INDEX_1 = 1;
export const DRAWER_Z_INDEX_2 = 2;
export const DRAWER_Z_INDEX_3 = 3;
export const DRAWER_Z_INDEX_10000 = 10000;

export const DEFAULT_EMPTY_VALUE = -1;
export const DEFAULT_VALUE_0 = 0;

export const SELECT_LIST_HEIGHT_210 = 210;
export const SELECT_LIST_HEIGHT_320 = 320;
export const SELECT_LIST_HEIGHT_334 = 334;

export const JIVO_SRC = '//code.jivo.ru/widget/WgVeogLWuw';
export const ALL_DOCS_EXTS = 'image/jpeg, image/png';
export const RANGE_VALUE_MIN = '0.1';
export const DEFAULT_COUNTER_MAX_VALUE = 10000;
export const STATISTICS_OVERFLOW_COUNT = 999;
export const DEFAULT_DELIVERY_IN_DAYS = 90;
export const CHARACTER_LIMIT_MIN = 3;
export const SUPPLIES_COUNT_MIN = 1;
export const DEFAULT_GOODS_COUNT = 1;
export const UPLOADING_DOCS_COUNT = 1;
export const DEFAULT_PAGINATION_PAGE = 1;
export const CATALOG_FILTER_CHIPS_MIN = 1;
export const PRESET_LIST_LIMIT = 1;
export const DEFAULT_BASIC_DISCOUNT = 0;
export const DEFAULT_LIST_OFFSET = 0;
