import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { RootDispatch } from 'app/store';
import { PropertyCascader } from 'entities/Property/components/PropertyCascader';
import { PropertyForm } from 'entities/Property/components/PropertyForm';

type AllType = ReturnType<typeof mapDispatch>;

const PropertiesSettingsComponent: FC<AllType> = (props) => {
  const { getPropertyList, setPropertyList, setCascaderItems, setCascaderSelectedPropertyId } = props;

  useEffect(() => {
    return () => {
      setPropertyList([]);
      setCascaderItems([]);
      setCascaderSelectedPropertyId(null);
    };
  }, []);

  const { contentLoading } = useContentLoader(async () => {
    await getPropertyList({ limit: 0 });
  });

  return (
    <div className="properties-settings">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <PropertyCascader />

          <PropertyForm />
        </>
      )}
    </div>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  getPropertyList: dispatch.propertyListState.getPropertyList,
  setPropertyList: dispatch.propertyListState.setPropertyList,
  setCascaderItems: dispatch.propertyCascaderState.setCascaderItems,
  setCascaderSelectedPropertyId: dispatch.propertyCascaderState.setCascaderSelectedPropertyId,
});

export const PropertiesSettings = connect(null, mapDispatch)(PropertiesSettingsComponent);
