import React, { FC } from 'react';
import { Button, Drawer, Spin } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InventoryCount } from 'common/components/InventoryCount';
import { Image } from 'common/components/Image';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ERoute } from 'common/const/route.enum';
import { EInventoryActionType, EInventoryStatus } from 'common/const/inventory.enum';
import { countFormatter, dateFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import { useGoodsContext } from 'common/hooks/useGoodsContext';
import { DRAWER_Z_INDEX_1, LIST_LIMIT_0 } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as HistoryIcon } from 'app/assets/images/redesign/history.svg';
import { ReactComponent as ArrowRightLongIcon } from 'app/assets/images/redesign/arrow-right-long.svg';
import { ReactComponent as InfoGreenIcon } from 'app/assets/images/redesign/info-green.svg';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { getUserName, getUserRole } from 'entities/User/User.helper';
import { getInventoryCardNotificationMessage, inventoryCount } from 'entities/Inventory/Inventory.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    inventory,
    inventoryLoading,
    currentUser,
    currentBasket,
    goodsLoading,
    // dispatch
    filterInventoryListActions,
    filterInventoryActions,
    getInventoryUserList,
    deleteInventoryAction,
  } = props;

  const navigate = useNavigate();
  const { setIsNestedCard, openInventoryCard, setOpenInventoryCard, setOpenInventoryTransferCard, setOpenInventoryWriteOffCard } =
    useInventoryContext();
  const { onOpenGoodsCard } = useGoodsContext();

  const isAvailableInventory = inventory?.status === EInventoryStatus.Available;
  const isWriteOffInventory = inventory?.status === EInventoryStatus.WriteOff;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const availableCount = inventoryCount.withoutAllRequestsCount(inventory);
  const transferRequestCount = inventoryCount.transferRequestsCount(inventory);
  const writeOffRequestCount = inventoryCount.writeOffRequestCount(inventory);

  const onClose = () => {
    if (inventoryLoading) {
      return;
    }

    setOpenInventoryCard(false);
  };

  const onTransferInventoryClick = async () => {
    await getInventoryUserList({ limit: LIST_LIMIT_0 });
    setIsNestedCard(true);
    setOpenInventoryCard(false);
    setOpenInventoryTransferCard(true);
  };

  const onWriteOffInventoryClick = async () => {
    setIsNestedCard(true);
    setOpenInventoryCard(false);
    setOpenInventoryWriteOffCard(true);
  };

  const onCancelInventoryActionClick = (id: number) => {
    if (inventory) {
      deleteInventoryAction({
        ids: [id],
        onSuccess: () => {
          filterInventoryActions(id);
          filterInventoryListActions({ inventoryId: inventory.id, id });
        },
      });
    }
  };

  const onGoodsClick = (id: number) => {
    if (currentBasket) {
      const goodsInBasket = currentBasket.goods.some((goods) => goods.goodId === id);

      onOpenGoodsCard(id, goodsInBasket);
    }
  };

  if (!inventory) {
    return null;
  }

  return (
    <Drawer
      rootClassName="redesign drawer"
      open={openInventoryCard}
      onClose={onClose}
      width={800}
      destroyOnClose
      zIndex={DRAWER_Z_INDEX_1}
    >
      <div className="drawer__body">
        <div className="drawer__title mb-52">{inventory.good.name}</div>

        {isAvailableInventory && (
          <div className="mb-32">
            <InventoryCount count={inventory.count} />
          </div>
        )}

        <div className="inventory-card__info mb-32">
          {isAvailableInventory && inventory.user && (
            <div className="inventory-card__info-container">
              <UserIcon className="icon-user-dark-grey" />

              <span className="text-tag-accent color-dark-grey">
                Ответственный: {getUserName(inventory.user.firstName, inventory.user.lastName)}
              </span>
            </div>
          )}

          {isWriteOffInventory && (
            <div className="inventory-card__info-container">
              <ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />

              <span className="text-tag-accent color-dark-grey">Списан в количестве {inventory.count}</span>
            </div>
          )}

          <div className="inventory-card__info-container">
            <HistoryIcon className="icon-history-dark-grey" />

            {transferRequestCount ? (
              <span className="text-tag-accent color-dark-grey">Запрос о передаче {countFormatter(transferRequestCount)}</span>
            ) : (
              <span className="text-tag-accent color-dark-grey">
                Обновлено {dateFormatter.fullDateDotSeparator(inventory.updatedAt)}
              </span>
            )}
          </div>

          {!!writeOffRequestCount && (
            <div className="inventory-card__info-container">
              <ArrowRightLongIcon className="icon-arrow-right-long-dark-grey" />

              <span className="text-tag-accent color-dark-grey">Запрос о списании {countFormatter(writeOffRequestCount)}</span>
            </div>
          )}
        </div>

        <Spin spinning={goodsLoading} indicator={<SpinIndicator />}>
          <div className="inventory-card__good-card mb-52" onClick={() => onGoodsClick(inventory.good.id)}>
            <Image size={80} src={inventory.good.image} />

            <div style={{ flex: 1 }}>
              <div className="text-h4 mb-12">{inventory.good.name}</div>

              <div className="inventory-card__good-card-footer">
                <div>
                  {inventory.good.sellerCode && (
                    <span className="text-tag-accent color-dark-grey" style={{ marginRight: 20 }}>
                      {inventory.good.sellerCode}
                    </span>
                  )}

                  {inventory.good.buyerCode && (
                    <span className="text-tag-accent color-bright-green">{inventory.good.buyerCode}</span>
                  )}
                </div>

                <div className="text-accent">{priceFormatter(inventory.good.price)}</div>
              </div>
            </div>
          </div>
        </Spin>

        {!!inventory.actions.length && (
          <div className="mb-52">
            {inventory.actions.map((action) => {
              const message = getInventoryCardNotificationMessage(inventory, action);

              if (action.type === EInventoryActionType.Transfer) {
                return (
                  <div key={action.id} className="inventory-card__notification mb-8">
                    <InfoGreenIcon className="icon-info-green" />

                    <div style={{ flex: 1 }}>
                      <div className="text-h4 color-white mb-12">{`Запрошена передача ${action.count} шт`}</div>

                      <div className="text-tag color-white">{message}</div>

                      <Button
                        className="button-s black"
                        style={{ marginTop: 16 }}
                        onClick={() => onCancelInventoryActionClick(action.id)}
                      >
                        Отменить запрос
                      </Button>
                    </div>
                  </div>
                );
              }

              return (
                <div key={action.id} className="inventory-card__notification mb-8">
                  <InfoYellowIcon className="icon-info-yellow" />

                  <div style={{ flex: 1 }}>
                    <div className="text-h4 color-white mb-12">{`Запрошено списание ${action.count} шт`}</div>

                    <div className="text-tag color-white">{message}</div>

                    <Button
                      className="button-s black"
                      style={{ marginTop: 16 }}
                      onClick={() => onCancelInventoryActionClick(action.id)}
                    >
                      Отменить запрос
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!!inventory.workspacePositions.length && (
          <div className="inventory-card__block mb-72">
            <div className="text-h4 inventory-card__block-title">В карте оснащения</div>

            {inventory.workspacePositions.map(({ id, categoryName, workspaceId }) => {
              return (
                <Button
                  key={id}
                  style={{ marginRight: 10 }}
                  className="button-chip"
                  onClick={() => {
                    if (isAccountAdmin) {
                      navigate(`${ERoute.WorkspaceList}/${workspaceId}`);
                    } else {
                      navigate(ERoute.WorkspaceForUser);
                    }
                  }}
                >
                  {categoryName}
                </Button>
              );
            })}
          </div>
        )}
      </div>

      {inventory.status === EInventoryStatus.Available && !!availableCount && (
        <div className="drawer__footer">
          <Button className="button-l primary" onClick={onTransferInventoryClick} loading={inventoryLoading}>
            Передать инструмент
          </Button>

          <Button className="button-l secondary" onClick={onWriteOffInventoryClick} loading={inventoryLoading}>
            Отправить на списание
          </Button>
        </div>
      )}
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  inventory: state.inventoryState.data,
  inventoryLoading: state.inventoryState.loading,
  currentUser: state.userState.currentUser,
  currentBasket: state.basketState.currentBasket,
  goodsLoading: state.goodsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  filterInventoryListActions: dispatch.inventoryListState.filterActions,
  filterInventoryActions: dispatch.inventoryState.filterActions,
  getInventoryUserList: dispatch.inventoryState.getInventoryUserList,
  deleteInventoryAction: dispatch.inventoryActionState.deleteInventoryAction,
});

export const InventoryCard = connect(mapState, mapDispatch)(Component);
