import React, { FC } from 'react';
import { ReactComponent as InfoRedIcon } from 'app/assets/images/redesign/info-red.svg';

interface IComponentProps {
  message: string;
}

export const Error: FC<IComponentProps> = ({ message }) => {
  return (
    <div className="error mb-32">
      <InfoRedIcon className="icon-info-red" />

      <span className="text-tag color-white">{message}</span>
    </div>
  );
};
