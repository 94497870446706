import React, { FC } from 'react';
import { Button } from 'antd';
import { Preset } from 'common/components/Preset';
import { PRESET_LIST_LIMIT } from 'common/config';
import { IPreset } from 'common/models';
import { ReactComponent as CloseGreyIcon } from 'app/assets/images/redesign/close-grey.svg';

interface IComponentProps {
  list: IPreset[];
  rootPreset?: IPreset;
  onClose?: (id: number, value?: string) => void;
  onClearAll?: () => void;
}

export const PresetList: FC<IComponentProps> = ({ list, rootPreset, onClose, onClearAll }) => {
  const showClearAll =
    list.length > PRESET_LIST_LIMIT || list.some((item) => item.values && item.values.length > PRESET_LIST_LIMIT);

  return (
    <div className="preset-list mb-52">
      {rootPreset && <Preset item={rootPreset} />}

      {list.map((preset) => {
        return <Preset key={preset.propertyId} item={preset} onClose={(value) => onClose?.(preset.propertyId, value)} />;
      })}

      {showClearAll && (
        <div className="redesign preset is-clear-all">
          <span className="text-tag">Очистить всё</span>

          <Button className="button-icon" icon={<CloseGreyIcon className="icon-close-grey-red" />} onClick={onClearAll} />
        </div>
      )}
    </div>
  );
};
