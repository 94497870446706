import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmptyList } from 'common/components/EmptyList';
import { ERoute } from 'common/const/route.enum';
import { RootState } from 'app/store';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  open: boolean;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const Component: FC<AllType> = ({ open, currentUser }) => {
  const navigate = useNavigate();

  const { isAccountAdmin } = getUserRole(currentUser?.roles);

  return (
    <EmptyList
      open={open}
      width={730}
      title={isAccountAdmin ? 'Оборудование в подразделении отсутствует' : 'Оборудование в распоряжении отсутствует'}
      description={
        isAccountAdmin
          ? 'Найдите и закажите необходимое оборудование в каталоге товаров'
          : 'Найдите всё, что вам необходимо, в каталоге и закажите на ваше рабочее место'
      }
      btnTitle="В каталог"
      onClick={() => navigate(ERoute.Catalog)}
    />
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
});

export const InventoryEmptyList = connect(mapState)(Component);
