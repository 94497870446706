import React, { FC } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { NameLabel } from 'common/components/NameLabel';
import { SupplyStatus } from 'common/components/SupplyStatus';
import { Image } from 'common/components/Image';
import { countFormatter, dateFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { ESupplyStatus } from 'common/const/supply.enum';
import { RootState } from 'app/store';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ISupply } from 'entities/Supply/Supply.models';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  supply: ISupply;
  onSupplyClick: (id: number) => void;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const SupplyCardMinComponent: FC<AllType> = ({ supply, onSupplyClick, currentUser }) => {
  const { isSellerManager, isManager } = getUserRole(currentUser?.roles);

  return (
    <div className="supply-card-min" onClick={() => onSupplyClick(supply.id)}>
      <div className="mb-20 supply-card-min__header">
        <span className="text-h4">
          {supply.deliveryDate ? dateFormatter.fullDate(supply.deliveryDate) : 'Дата доставки не установлена'}
        </span>

        {isManager && supply.status === ESupplyStatus.Pending && (
          <Button className="button-s secondary">Требуется подтверждение</Button>
        )}

        {isSellerManager && supply.status === ESupplyStatus.Created && (
          <Button className="button-s secondary">Требуется подтверждение</Button>
        )}

        {isSellerManager && supply.status === ESupplyStatus.Pending && (
          <Button className="button-s secondary">Отменить поставку</Button>
        )}

        {isSellerManager && supply.status === ESupplyStatus.Approved && (
          <Button className="button-s secondary">Разместить в УС</Button>
        )}

        {isSellerManager && (supply.status === ESupplyStatus.Processing || supply.status === ESupplyStatus.Delivery) && (
          <Button className="button-s secondary">Изменить статус заказа</Button>
        )}
      </div>

      <div className="supply-card-min__container">
        <div className="supply-card-min__header">
          <span>
            <span className="text-lists color-dark-grey">Поставка</span>

            <span className="text-lists">{' #'}</span>

            <span className="text-accent">{supply.id}</span>
          </span>

          <SupplyStatus status={supply.status} isCancelledBySeller={supply.isCancelledBySeller} />
        </div>

        <div className="supply-card-min__divider" />

        <div className="supply-card-min__card">
          <Image size={80} src={supply.good.image} />

          <div className="supply-card-min__card-table">
            <div className="supply-card-min__card-table-row">
              <span className="text-body color-dark-grey">Количество:</span>

              <span className="text-body">{countFormatter(supply.count)}</span>
            </div>

            <div className="supply-card-min__card-table-row">
              <span className="text-body color-dark-grey">Стоимость товаров:</span>

              <span className="text-body">{priceFormatter(supply.count * supply.price)}</span>
            </div>

            <div className="supply-card-min__card-table-row">
              <span className="text-body color-dark-grey">Поставщик:</span>

              <NameLabel icon={<DeliveryIcon className="icon-delivery-bright-green" />} name={supply.sellerName} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  currentUser: state.userState.currentUser,
});

export const SupplyCardMin = connect(mapState)(SupplyCardMinComponent);
