import axios from 'axios';
import {
  IGroupedWorkspace,
  IWorkspace,
  IWorkspaceCollectionDto,
  IWorkspaceCopyPayload,
  IWorkspaceCreatePayload,
  IWorkspaceDeletePayload,
  IWorkspaceListParams,
  IWorkspacePosition,
  IWorkspacePositionCreatePayload,
  IWorkspacePositionDeletePayload,
  IWorkspacePositionUpdatePayload,
  IWorkspaceUpdatePayload,
} from 'entities/Workspace/Workspace.models';

const basePath = 'workspace';

export const workspaceTransport = {
  getWorkspaceList: (params: IWorkspaceListParams): Promise<IWorkspaceCollectionDto> => axios.get(`${basePath}`, { params }),
  getWorkspaceById: (id: number): Promise<IWorkspace> => axios.get(`${basePath}/${id}`),
  createWorkspace: (payload: IWorkspaceCreatePayload): Promise<IWorkspace> => axios.post(`${basePath}`, payload),
  updateWorkspace: (payload: IWorkspaceUpdatePayload): Promise<IWorkspace> => axios.patch(`${basePath}`, payload),
  copyWorkspace: (payload: IWorkspaceCopyPayload): Promise<IWorkspace> => axios.post(`${basePath}/copy`, payload),
  deleteWorkspace: (payload: IWorkspaceDeletePayload): Promise<IWorkspace> => axios.delete(`${basePath}`, { data: payload }),
  getGroupedWorkspaceById: (id: number): Promise<IGroupedWorkspace> => axios.get(`${basePath}/grouped/${id}`),
  getWorkspacePositionById: (id: number): Promise<IWorkspacePosition> => axios.get(`${basePath}/position/${id}`),
  createWorkspacePosition: (payload: IWorkspacePositionCreatePayload): Promise<IWorkspacePosition> =>
    axios.post(`${basePath}/position`, payload),
  updateWorkspacePosition: (payload: IWorkspacePositionUpdatePayload): Promise<IWorkspacePosition> =>
    axios.patch(`${basePath}/position`, payload),
  deleteWorkspacePosition: (payload: IWorkspacePositionDeletePayload): Promise<IWorkspacePosition> =>
    axios.delete(`${basePath}/position`, { data: payload }),
};
