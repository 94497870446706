import { createModel } from '@rematch/core';
import { DEFAULT_VALUE_0 } from 'common/config';
import { IRootModel } from 'app/store';
import { IUserInventoryStatisticsState, IUserListState, IUserState, IUserStatisticsState } from 'entities/User/User.models';
import {
  accountUserListStateReducers,
  statisticsStateReducers,
  userInventoryStatisticsStateReducers,
  userListStateReducers,
  userStateReducers,
} from 'entities/User/User.reducers';
import {
  accountUserListStateEffects,
  statisticsStateEffects,
  userInventoryStatisticsStateEffects,
  userListStateEffects,
  userStateEffects,
} from 'entities/User/User.effects';

export const userListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IUserListState,
  reducers: userListStateReducers,
  effects: userListStateEffects,
});

export const userState = createModel<IRootModel>()({
  state: {
    data: null,
    currentUser: null,
    loading: false,
    error: null,
  } as IUserState,
  reducers: userStateReducers,
  effects: userStateEffects,
});

export const accountUserListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IUserListState,
  reducers: accountUserListStateReducers,
  effects: accountUserListStateEffects,
});

export const statisticsState = createModel<IRootModel>()({
  state: {
    data: {
      requestsCount: DEFAULT_VALUE_0,
      basketGoodsCount: DEFAULT_VALUE_0,
      sellerRequestsCount: DEFAULT_VALUE_0,
    },
    loading: true,
  } as IUserStatisticsState,
  reducers: statisticsStateReducers,
  effects: statisticsStateEffects,
});

export const userInventoryStatisticsState = createModel<IRootModel>()({
  state: {
    data: {
      inventoryAvailableCount: DEFAULT_VALUE_0,
      inventoryWrittenOffCount: DEFAULT_VALUE_0,
      incomingTransfersCount: DEFAULT_VALUE_0,
      outcomingTransfersCount: DEFAULT_VALUE_0,
      writeOffsCount: DEFAULT_VALUE_0,
    },
    loading: true,
  } as IUserInventoryStatisticsState,
  reducers: userInventoryStatisticsStateReducers,
  effects: userInventoryStatisticsStateEffects,
});
