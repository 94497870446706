import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Form, InputNumber, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Error } from 'common/components/Error';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { PresetList } from 'common/components/PresetList';
import { EEditPositionCardMode } from 'common/const/workspace.enum';
import { rules } from 'common/helpers/form.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useWorkspaceContext } from 'common/hooks/useWorkspaceContext';
import { IFormValues } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { IProperty } from 'entities/Property/Property.models';
import {
  filterNotApplicableProperties,
  getPropertyPresetType,
  mapPropertyListToPresetList,
  propertyRangeResultToRangeValue,
  renderPropertyFormField,
  setPropertyListItemHidden,
  setPropertyListResult,
} from 'entities/Property/Property.helper';
import {
  getWorkspacePositionFormInitialValueEntries,
  mapFormValuesToWorkspacePositionPropertyListWithResult,
} from 'entities/Workspace/Workspace.helper';
import { IWorkspaceCategory } from 'entities/Workspace/Workspace.models';
import { WorkspaceSimilarPositions } from 'entities/Workspace/components/WorkspaceSimilarPositions';
import { mapCategoryToPreset } from 'entities/Categories/Categories.helper';

interface IComponentProps {
  categories?: IWorkspaceCategory[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const Component: FC<AllType> = (props) => {
  const {
    // props
    categories,
    // state
    propertyList,
    workspacePosition,
    workspacePositionLoading,
    workspacePositionError,
    // dispatch
    setPropertyList,
    setWorkspacePositionError,
    createWorkspacePosition,
    updateWorkspacePosition,
  } = props;

  const [propertiesForView, setPropertiesForView] = useState<IProperty[]>([]);
  const [form] = useForm();
  const { openEditPositionCard, setOpenEditPositionCard, editPositionCardMode, setEditPositionCardMode } = useWorkspaceContext();

  const isCopyMode = editPositionCardMode === EEditPositionCardMode.Copy;
  const requiredProperties = propertiesForView.filter((property) => !!property.isRequiredForPresets);
  const optionalProperties = propertiesForView.filter((property) => !property.isRequiredForPresets);
  const presetList = mapPropertyListToPresetList(propertiesForView);

  const handleClose = () => {
    if (workspacePositionLoading) {
      return;
    }

    setOpenEditPositionCard(false);
    setEditPositionCardMode(EEditPositionCardMode.Edit);
    setWorkspacePositionError(null);
    setPropertyList([]);
  };

  const onPresetClose = (id: number, value?: string) => {
    const newPropertiesForViewWithResult = propertiesForView.map((property) => {
      const { isMultipleValue, isCheckbox } = getPropertyPresetType(property);

      if (property.id === id) {
        if (isMultipleValue || isCheckbox) {
          const result = property.result?.filter((item) => item !== value);

          form.setFieldValue(property.id, result);

          return { ...property, result };
        }

        form.resetFields([property.id]);

        return { ...property, result: [] };
      }

      return property;
    });

    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    setWorkspacePositionError(null);
    setPropertiesForView(newPropertiesForVieWithHidden);
  };

  const onClearAllPresetClick = () => {
    const propertyListIds = propertiesForView.map((property) => property.id);
    const newPropertiesForViewWithResult = propertiesForView.map((property) => ({ ...property, result: [] }));
    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    setWorkspacePositionError(null);
    setPropertiesForView(newPropertiesForVieWithHidden);
    form.resetFields(propertyListIds);
  };

  const onFormValuesChange = (values: IFormValues) => {
    const newPropertiesForViewWithResult = mapFormValuesToWorkspacePositionPropertyListWithResult(values, propertiesForView);
    const newPropertiesForVieWithHidden = setPropertyListItemHidden(newPropertiesForViewWithResult);

    newPropertiesForVieWithHidden.forEach((property) => {
      if (property.hidden) {
        form.resetFields([property.id]);
      }
    });

    setWorkspacePositionError(null);
    setPropertiesForView(newPropertiesForVieWithHidden);
  };

  const onEditSubmit = (values: IFormValues) => {
    if (workspacePosition) {
      const { inventoryLimit } = values;

      updateWorkspacePosition({
        id: workspacePosition.id,
        categoryId: workspacePosition.categoryId,
        inventoryLimit,
        properties: propertiesForView
          .filter((property) => !!property.result?.length)
          .map((property) => {
            const { isRange } = getPropertyPresetType(property);

            return {
              propertyId: property.id,
              values: !isRange ? property.result : undefined,
              range: isRange ? propertyRangeResultToRangeValue(property.result) : undefined,
            };
          }),
        onSuccess: () => {
          handleClose();
          showSuccessMessage('Позиция была отредактирована.');
        },
      });
    }
  };

  const onCopySubmit = (values: IFormValues) => {
    if (workspacePosition) {
      const { inventoryLimit } = values;

      createWorkspacePosition({
        workspaceId: workspacePosition.workspaceId,
        categoryId: workspacePosition.categoryId,
        inventoryLimit,
        properties: propertiesForView
          .filter((property) => !!property.result?.length)
          .map((property) => {
            const { isRange } = getPropertyPresetType(property);

            return {
              propertyId: property.id,
              values: !isRange ? property.result : undefined,
              range: isRange ? propertyRangeResultToRangeValue(property.result) : undefined,
            };
          }),
        onSuccess: () => {
          handleClose();
          showSuccessMessage('Добавлена новая позиция.');
        },
      });
    }
  };

  useEffect(() => {
    if (workspacePosition) {
      form.resetFields();

      const filteredPropertyList = filterNotApplicableProperties(propertyList);
      const propertyListWithResult = setPropertyListResult.forEditWorkspacePosition(filteredPropertyList, workspacePosition);
      const propertiesForView = setPropertyListItemHidden(propertyListWithResult);
      const formInitialValueEntries = getWorkspacePositionFormInitialValueEntries(workspacePosition);

      form.setFieldsValue({ inventoryLimit: workspacePosition.inventoryLimit, ...Object.fromEntries(formInitialValueEntries) });
      setPropertiesForView(propertiesForView);
    }
  }, [workspacePosition, propertyList]);

  if (!workspacePosition) {
    return null;
  }

  return (
    <Drawer className="redesign drawer" open={openEditPositionCard} width={1260} onClose={handleClose}>
      <div className="drawer__body">
        <Spin wrapperClassName="workspace-position__spin" spinning={workspacePositionLoading} indicator={<SpinIndicator />}>
          <div className="drawer__title">{isCopyMode ? 'Уточните параметры позиции' : 'Редактировать позицию'}</div>

          <PresetList
            list={presetList}
            rootPreset={mapCategoryToPreset(workspacePosition.categoryName)}
            onClose={onPresetClose}
            onClearAll={onClearAllPresetClick}
          />

          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            scrollToFirstError
            onValuesChange={onFormValuesChange}
            onFinish={isCopyMode ? onCopySubmit : onEditSubmit}
          >
            <div className="mb-72">
              <div className="text-h4 mb-32">Обязательные параметры*</div>

              <div className="workspace-position__properties">
                <Form.Item className="mb-32" label="Норма хранения*" name="inventoryLimit" rules={[rules.required()]}>
                  <InputNumber suffix="шт" controls={false} />
                </Form.Item>
              </div>

              {!!requiredProperties.length && (
                <div className="workspace-position__properties">
                  {requiredProperties.map((property) => renderPropertyFormField(property))}
                </div>
              )}
            </div>

            {!!optionalProperties.length && (
              <div className="mb-72">
                <div className="text-h4 mb-32">Дополнительные параметры</div>

                <div className="workspace-position__properties">
                  {optionalProperties.map((property) => renderPropertyFormField(property))}
                </div>
              </div>
            )}
          </Form>

          {workspacePositionError && <Error message={workspacePositionError} />}

          <WorkspaceSimilarPositions
            position={workspacePosition}
            category={categories?.find((category) => category.categoryId === workspacePosition.categoryId)}
          />
        </Spin>
      </div>

      <div className="drawer__footer mb-72">
        <Button className="button-l primary" onClick={form.submit} loading={workspacePositionLoading}>
          Сохранить позицию
        </Button>
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  propertyList: state.propertyListState.data,
  workspacePosition: state.workspacePositionState.data,
  workspacePositionLoading: state.workspacePositionState.loading,
  workspacePositionError: state.workspacePositionState.error,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setPropertyList: dispatch.propertyListState.setPropertyList,
  setWorkspacePositionError: dispatch.workspacePositionState.setError,
  createWorkspacePosition: dispatch.workspacePositionState.createWorkspacePosition,
  updateWorkspacePosition: dispatch.workspacePositionState.updateWorkspacePosition,
});

export const EditWorkspacePositionCard = connect(mapState, mapDispatch)(Component);
