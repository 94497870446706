import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Tooltip } from 'antd';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { ReactComponent as ToolIcon } from 'app/assets/images/redesign/tool.svg';
import { ReactComponent as ArrowLeftShortIcon } from 'app/assets/images/redesign/arrow-left-short.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { ReactComponent as PlusIcon } from 'app/assets/images/redesign/plus.svg';
import { IWorkspaceCatalogCategory } from 'entities/Workspace/Workspace.models';
import { sortWorkspaceLowerLevelCategories } from 'entities/Workspace/Workspace.helper';

interface IComponentProps {
  editable?: boolean;
  catalog?: IWorkspaceCatalogCategory[];
  category: IWorkspaceCatalogCategory | null;
  setCategory: (value: IWorkspaceCatalogCategory | null) => void;
  onCopyClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onCreatePosition: () => void;
}

export const WorkspaceTools: FC<IComponentProps> = (props) => {
  const { editable, catalog, category, setCategory, onCopyClick, onEditClick, onDeleteClick, onCreatePosition } = props;

  const [upperLevelCategories, setUpperLevelCategories] = useState<IWorkspaceCatalogCategory[]>([]);
  const [lowerLevelCategories, setLowerLevelCategories] = useState<IWorkspaceCatalogCategory[]>([]);

  const sortedLowerLevelCategories = useMemo(() => {
    return sortWorkspaceLowerLevelCategories(lowerLevelCategories);
  }, [lowerLevelCategories]);

  const getCategoryClassName = (id: number) => `workspace-tools__item ${category?.categoryId === id ? 'active' : ''}`;

  const getLastUpperLevelCategory = (id: number) => id === upperLevelCategories[upperLevelCategories.length - 1].categoryId;

  const onCategoryClick = (value: IWorkspaceCatalogCategory) => {
    if (catalog) {
      if (value.categoryId === DEFAULT_EMPTY_VALUE) {
        setCategory(null);
        setUpperLevelCategories([]);
        setLowerLevelCategories(catalog);
      } else {
        setCategory(value);

        if (value.children.length) {
          setLowerLevelCategories(value.children);

          if (!upperLevelCategories.length) {
            setUpperLevelCategories([
              { categoryId: DEFAULT_EMPTY_VALUE, categoryName: 'Все инструменты' } as IWorkspaceCatalogCategory,
              value,
            ]);
          } else {
            const index = upperLevelCategories.findIndex((category) => category.categoryId === value.categoryId);

            if (index === DEFAULT_EMPTY_VALUE) {
              setUpperLevelCategories((prev) => [...prev, value]);
            } else {
              setUpperLevelCategories((prev) => [...prev.slice(0, index), value]);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!!catalog?.length && !upperLevelCategories.length) {
      setLowerLevelCategories(catalog);
    }
  }, [catalog, upperLevelCategories]);

  return (
    <div className="workspace-tools">
      <div className="workspace-tools__header">
        {editable && (
          <>
            <Tooltip title="Добавить инструмент">
              <Button className="button-circle primary" icon={<PlusIcon />} onClick={onCreatePosition} />
            </Tooltip>

            <Dropdown
              overlayClassName="redesign"
              menu={{
                items: [
                  { key: 'edit', label: 'Редактировать карту', onClick: onEditClick },
                  { type: 'divider' },
                  { key: 'copy', label: 'Дублировать карту', onClick: onCopyClick },
                  { type: 'divider' },
                  { key: 'delete', label: 'Удалить карту', onClick: onDeleteClick, className: 'red' },
                ],
              }}
              placement="bottomRight"
            >
              <Button className="button-circle secondary" icon={<OptionsIcon />} />
            </Dropdown>
          </>
        )}
      </div>

      <div className="workspace-tools__body">
        <InfiniteScrollContainer>
          <div className="text-h1-drawers mb-52">Инструменты</div>

          {!sortedLowerLevelCategories.length ? (
            <div className="text-body color-dark-grey">В карте ещё нет разделов.</div>
          ) : (
            <div className="mb-52">
              {upperLevelCategories.map((item) => {
                const className = getCategoryClassName(item.categoryId);
                const isLastItem = getLastUpperLevelCategory(item.categoryId);

                return (
                  <div key={item.categoryId} className={className} onClick={() => onCategoryClick(item)}>
                    <div className="workspace-tools__item-container">
                      {!!category && isLastItem ? (
                        <ToolIcon className="icon-tool-dark-grey" />
                      ) : (
                        <ArrowLeftShortIcon className="icon-arrow-left-short-dark-grey" />
                      )}

                      <div className="workspace-tools__item-label">{item.categoryName}</div>
                    </div>
                  </div>
                );
              })}

              {sortedLowerLevelCategories.map((item) => {
                const className = getCategoryClassName(item.categoryId);

                return (
                  <div key={item.categoryId} className={className} onClick={() => onCategoryClick(item)}>
                    <div className="workspace-tools__item-container">
                      <div className="workspace-tools__item-label">{item.categoryName}</div>
                    </div>

                    {!item.children.length && (
                      <div className="text-tag color-dark-grey" style={{ marginTop: 4 }}>
                        {`${item.positionsCount} позиций`}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </InfiniteScrollContainer>
      </div>
    </div>
  );
};
