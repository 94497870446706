import React, { FC } from 'react';
import { Button } from 'antd';
import { EPresetType } from 'common/const/preset.enum';
import { getPresetRangeValue } from 'common/helpers/preset.helper';
import { IPreset } from 'common/models';
import { ReactComponent as CloseGreyIcon } from 'app/assets/images/redesign/close-grey.svg';

interface IComponentProps {
  item: IPreset;
  onClose?: (value?: string) => void;
}

export const Preset: FC<IComponentProps> = ({ item, onClose }) => {
  const { propertyId, propertyName, isCategory, isRequiredForPresets, presetType, values, range, unitOfMeasurement } = item;

  const isSingleValue = presetType === EPresetType.SingleValue;
  const isMultipleValue = presetType === EPresetType.MultipleValue;
  const isRange = presetType === EPresetType.Range;
  const isCheckbox = presetType === EPresetType.Checkbox;
  const unit = unitOfMeasurement ? unitOfMeasurement : '';
  const isRequiredClassName = isRequiredForPresets ? 'is-required' : '';
  const deleteBtnIconClassName = `icon-close-grey-${isRequiredForPresets ? 'blue' : 'dark-grey'}`;

  if (isSingleValue) {
    return (
      <div key={propertyId} className={`redesign preset ${isCategory ? 'is-category' : ''} ${isRequiredClassName}`}>
        <span className="text-tag">{`${propertyName}:`}</span>

        <span className="text-tag-accent">
          {values?.[0]} {unit}
        </span>

        {onClose && (
          <Button className="button-icon" icon={<CloseGreyIcon className={deleteBtnIconClassName} />} onClick={() => onClose()} />
        )}
      </div>
    );
  }

  if (isMultipleValue || isCheckbox) {
    return (
      <>
        {/*  */}
        {values?.map((value, index) => {
          return (
            <div key={`${propertyId}-${index}`} className={`redesign preset ${isRequiredClassName}`}>
              <span className="text-tag">{`${propertyName}:`}</span>

              <span className="text-tag-accent">
                {value} {unit}
              </span>

              {onClose && (
                <Button
                  className="button-icon"
                  icon={<CloseGreyIcon className={deleteBtnIconClassName} onClick={() => onClose(value)} />}
                />
              )}
            </div>
          );
        })}
      </>
    );
  }

  if (isRange) {
    return (
      <div key={propertyId} className={`redesign preset ${isRequiredClassName}`}>
        <span className="text-tag">{`${propertyName}:`}</span>

        <span className="text-tag-accent">
          {getPresetRangeValue(range)} {unit}
        </span>

        {onClose && (
          <Button className="button-icon" icon={<CloseGreyIcon className={deleteBtnIconClassName} />} onClick={() => onClose()} />
        )}
      </div>
    );
  }

  return null;
};
