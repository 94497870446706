import { RematchDispatch } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IWorkspaceCopyPayload,
  IWorkspaceCreatePayload,
  IWorkspaceDeletePayload,
  IWorkspaceListParams,
  IWorkspacePositionCreatePayload,
  IWorkspacePositionDeletePayload,
  IWorkspacePositionUpdatePayload,
  IWorkspaceUpdatePayload,
} from 'entities/Workspace/Workspace.models';
import { workspaceTransport } from 'entities/Workspace/Workspace.transport';

export const workspaceListStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getWorkspaceList(params: IWorkspaceListParams) {
    dispatch.workspaceListState.setLoading(true);

    try {
      const response = await workspaceTransport.getWorkspaceList(params);
      dispatch.workspaceListState.setWorkspaceList(response.data);
      dispatch.workspaceListState.setCount(response.count);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.workspaceListState.setLoading(false);
    }
  },
});

export const workspaceStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getWorkspaceById(id: number) {
    dispatch.workspaceState.setLoading(true);

    try {
      const response = await workspaceTransport.getWorkspaceById(id);
      dispatch.workspaceState.setWorkspace(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.workspaceState.setLoading(false);
    }
  },
  async createWorkspace(payload: IWorkspaceCreatePayload) {
    dispatch.workspaceState.setLoading(true);

    try {
      const response = await workspaceTransport.createWorkspace(payload);
      dispatch.workspaceListState.addWorkspace(response);
      dispatch.userListState.updateWorkspace(response);
      dispatch.userState.updateWorkspace(response);
      dispatch.workspaceState.setWorkspace(response);
      dispatch.workspaceState.onSuccess(payload);
      return response;
    } catch (error) {
      axiosErrorHandler(error, dispatch.workspaceState.setError);
      return null;
    } finally {
      dispatch.workspaceState.setLoading(false);
    }
  },
  async updateWorkspace(payload: IWorkspaceUpdatePayload) {
    dispatch.workspaceState.setLoading(true);

    try {
      const response = await workspaceTransport.updateWorkspace(payload);
      dispatch.workspaceListState.updateWorkspace(response);
      dispatch.groupedWorkspaceState.updateWorkspace(response);
      dispatch.userListState.updateWorkspace(response);
      dispatch.userState.updateWorkspace(response);
      dispatch.workspaceState.setWorkspace(response);
      dispatch.workspaceState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.workspaceState.setError);
    } finally {
      dispatch.workspaceState.setLoading(false);
    }
  },
  async copyWorkspace(payload: IWorkspaceCopyPayload) {
    dispatch.workspaceState.setLoading(true);

    try {
      const response = await workspaceTransport.copyWorkspace(payload);
      dispatch.workspaceListState.addWorkspace(response);
      dispatch.userListState.updateWorkspace(response);
      dispatch.userState.updateWorkspace(response);
      dispatch.workspaceState.setWorkspace(response);
      dispatch.workspaceState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.workspaceState.setError);
    } finally {
      dispatch.workspaceState.setLoading(false);
    }
  },
  async deleteWorkspace(payload: IWorkspaceDeletePayload) {
    dispatch.workspaceState.setLoading(true);

    try {
      const response = await workspaceTransport.deleteWorkspace(payload);
      dispatch.workspaceListState.deleteWorkspace(response);
      dispatch.userListState.deleteWorkspace(response);
      dispatch.userState.deleteWorkspace(response);
      dispatch.workspaceState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.workspaceState.setLoading(false);
    }
  },
});

export const groupedWorkspaceStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  async getGroupedWorkspaceById(id: number) {
    dispatch.groupedWorkspaceState.setLoading(true);

    try {
      const response = await workspaceTransport.getGroupedWorkspaceById(id);
      dispatch.groupedWorkspaceState.setGroupedWorkspace(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.groupedWorkspaceState.setLoading(false);
    }
  },
});

export const workspacePositionStateEffects = (dispatch: RematchDispatch<IRootModel>) => ({
  onSuccess<T extends IRequestSuccess>(payload: T) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  },
  async getWorkspacePositionById(id: number) {
    dispatch.workspacePositionState.setLoading(true);

    try {
      const response = await workspaceTransport.getWorkspacePositionById(id);
      dispatch.workspacePositionState.setPosition(response);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.workspacePositionState.setLoading(false);
    }
  },
  async createWorkspacePosition(payload: IWorkspacePositionCreatePayload) {
    dispatch.workspacePositionState.setLoading(true);

    try {
      const response = await workspaceTransport.createWorkspacePosition(payload);
      dispatch.groupedWorkspaceState.addPosition(response);
      dispatch.workspacePositionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.workspacePositionState.setError);
    } finally {
      dispatch.workspacePositionState.setLoading(false);
    }
  },
  async updateWorkspacePosition(payload: IWorkspacePositionUpdatePayload) {
    dispatch.workspacePositionState.setLoading(true);

    try {
      const response = await workspaceTransport.updateWorkspacePosition(payload);
      dispatch.groupedWorkspaceState.updatePosition(response);
      dispatch.workspacePositionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error, dispatch.workspacePositionState.setError);
    } finally {
      dispatch.workspacePositionState.setLoading(false);
    }
  },
  async deleteWorkspacePosition(payload: IWorkspacePositionDeletePayload) {
    dispatch.workspacePositionState.setLoading(true);

    try {
      const response = await workspaceTransport.deleteWorkspacePosition(payload);
      dispatch.groupedWorkspaceState.deletePosition(response);
      dispatch.workspacePositionState.onSuccess(payload);
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      dispatch.workspacePositionState.setLoading(false);
    }
  },
});
