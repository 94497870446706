import React, { FC, ReactNode, useMemo } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { CountBadge } from 'common/components/CountBadge';
import { EInventoryListMenuTab } from 'common/const/inventory.enum';
import { ESearchParams } from 'common/const/searchParams.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useInventoryContext } from 'common/hooks/useInventoryContext';
import {
  CHARACTER_LIMIT_MIN,
  DEFAULT_EMPTY_VALUE,
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_VALUE_0,
  LIST_LIMIT_0,
  SELECT_LIST_HEIGHT_320,
} from 'common/config';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { RootDispatch, RootState } from 'app/store';
import { TransferIncomingInventoryActionList } from 'entities/Inventory/components/TransferIncomingInventoryActionList';
import { TransferSentInventoryActionList } from 'entities/Inventory/components/TransferSentInventoryActionList';
import { getInventoryListFilterOptions } from 'entities/Inventory/Inventory.helper';
import { IInventoryActionListPayload } from 'entities/Inventory/Inventory.models';
import { IUserInventoryStatistics } from 'entities/User/User.models';
import { getUserRole } from 'entities/User/User.helper';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
  statistics: IUserInventoryStatistics;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    statistics,
    // state
    subdivisionList,
    userList,
    currentUser,
    // dispatch
    getUserList,
    getInventoryActionList,
  } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();
  const { setPage, setSelectedInventoryActionList } = useInventoryContext();

  const { incomingTransfersCount, outcomingTransfersCount } = statistics;
  const { isAccountAdmin } = getUserRole(currentUser?.roles);
  const tab = getSearchParam(ESearchParams.Tab);
  const isTransferSentInventoryActionList = tab === EInventoryListMenuTab.Sent;
  const subdivisionOptions = getInventoryListFilterOptions.subdivision(subdivisionList);
  const userOptions = getInventoryListFilterOptions.user(userList);

  const getMenuItems = () => {
    const items = [];

    if (incomingTransfersCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.Incoming,
        label: (
          <>
            Входящие запросы
            <CountBadge count={incomingTransfersCount} />
          </>
        ),
      });
    }

    if (outcomingTransfersCount > DEFAULT_VALUE_0) {
      items.push({
        key: EInventoryListMenuTab.Sent,
        label: (
          <>
            Отправленные запросы
            <CountBadge count={outcomingTransfersCount} />
          </>
        ),
      });
    }

    return items;
  };

  const menu = useMemo(() => {
    return (
      <Menu
        items={getMenuItems()}
        selectedKeys={[tab === null ? EInventoryListMenuTab.Incoming : tab]}
        onClick={({ key }) => setSearchParam(ESearchParams.Tab, key)}
        mode="horizontal"
      />
    );
  }, [tab, statistics]);

  const renderFilters = (payload: IInventoryActionListPayload, setPayload: (value: IInventoryActionListPayload) => void) => {
    const getUserFilterValue = () => {
      if (isTransferSentInventoryActionList) {
        return payload?.receiverId !== undefined ? payload.receiverId : DEFAULT_EMPTY_VALUE;
      }

      return payload?.userId !== undefined ? payload.userId : DEFAULT_EMPTY_VALUE;
    };

    return (
      <div className="inventory-list__filters mb-12">
        {isAccountAdmin && (
          <>
            <Select
              rootClassName="redesign"
              className="inventory-list__filters-select"
              options={subdivisionOptions}
              value={payload?.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
              onChange={async (value: number) => {
                const newPayload = {
                  ...payload,
                  subdivisionIds: value !== DEFAULT_EMPTY_VALUE ? [value] : undefined,
                  userId: isTransferSentInventoryActionList ? payload.userId : undefined,
                  receiverId: undefined,
                };

                setPage(DEFAULT_PAGINATION_PAGE);
                setSelectedInventoryActionList([]);
                setPayload(newPayload);

                await getUserList({ subdivisionId: newPayload.subdivisionIds?.[0], limit: LIST_LIMIT_0 });
                await getInventoryActionList(newPayload);
              }}
              placeholder={EPlaceholder.SelectCompany}
              listHeight={SELECT_LIST_HEIGHT_320}
              virtual={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              className="inventory-list__filters-select"
              options={userOptions}
              value={getUserFilterValue()}
              onChange={(value) => {
                const newPayload = isTransferSentInventoryActionList
                  ? {
                      ...payload,
                      receiverId: value === DEFAULT_EMPTY_VALUE ? undefined : value,
                    }
                  : {
                      ...payload,
                      userId: value === DEFAULT_EMPTY_VALUE ? undefined : value,
                    };

                setPage(DEFAULT_PAGINATION_PAGE);
                setPayload(newPayload);
                setSelectedInventoryActionList([]);
                getInventoryActionList(newPayload);
              }}
              placeholder={EPlaceholder.SelectUser}
              listHeight={SELECT_LIST_HEIGHT_320}
              virtual={false}
              suffixIcon={<ArrowDownShortIcon />}
            />
          </>
        )}

        <Input
          style={{ flex: 1 }}
          placeholder={EPlaceholder.SearchByNameOrArticle}
          value={payload?.search}
          onChange={(e) => {
            const value = e.target.value;

            setPayload({ ...payload, search: value.trim().length >= CHARACTER_LIMIT_MIN ? value : undefined });
          }}
        />

        <Button
          className="button-circle primary"
          icon={<SearchIcon />}
          onClick={() => {
            setPage(DEFAULT_PAGINATION_PAGE);
            setSelectedInventoryActionList([]);
            getInventoryActionList(payload);
          }}
        />
      </div>
    );
  };

  return isTransferSentInventoryActionList ? (
    <TransferSentInventoryActionList
      header={header}
      navigation={navigation}
      menu={menu}
      statistics={statistics}
      renderFilters={renderFilters}
    />
  ) : (
    <TransferIncomingInventoryActionList
      header={header}
      navigation={navigation}
      menu={menu}
      statistics={statistics}
      renderFilters={renderFilters}
    />
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.subdivisionListState.data,
  userList: state.userListState.data,
  currentUser: state.userState.currentUser,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserList: dispatch.userListState.getUserList,
  getInventoryActionList: dispatch.inventoryActionListState.getInventoryActionList,
});

export const TransferInventoryActionList = connect(mapState, mapDispatch)(Component);
