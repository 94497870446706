import React, { FC, useState } from 'react';
import { Button, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { connect } from 'react-redux';
import { Preset } from 'common/components/Preset';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { InventoryCount } from 'common/components/InventoryCount';
import { EEditPositionCardMode } from 'common/const/workspace.enum';
import { showMessage } from 'common/helpers/message.helper';
import { countFormatter } from 'common/helpers/formatter.helper';
import { useWorkspaceContext } from 'common/hooks/useWorkspaceContext';
import { ReactComponent as DocumentIcon } from 'app/assets/images/redesign/document.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { IWorkspacePosition } from 'entities/Workspace/Workspace.models';
import { mapCategoryToPreset } from 'entities/Categories/Categories.helper';
import { WorkspacePositionInventoryLimit } from 'entities/Workspace/components/WorkspacePositionInventoryLimit';

interface IComponentProps {
  position: IWorkspacePosition;
  editable?: boolean;
  showPresets?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    position,
    editable = true,
    showPresets = true,
    // state
    workspacePositionLoading,
    // dispatch
    getPropertyListByCategoryId,
    getGoodsList,
    getWorkspacePositionById,
    deleteWorkspacePosition,
  } = props;

  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);

  const { setOpenPositionCard, setOpenEditPositionCard, setEditPositionCardMode } = useWorkspaceContext();

  const onCardClick = async () => {
    setOpenEditPositionCard(false);
    await getWorkspacePositionById(position.id);
    await getGoodsList({
      categoryId: position.categoryId,
      positions: position.properties.map((property) => {
        return {
          propertyId: property.propertyId,
          values: property.values,
          range: property.range,
        };
      }),
    });
    setOpenPositionCard(true);
  };

  const onEditClick = async () => {
    setOpenEditPositionCard(false);
    await getWorkspacePositionById(position.id);
    await getPropertyListByCategoryId(position.categoryId);
    setEditPositionCardMode(EEditPositionCardMode.Edit);
    setOpenEditPositionCard(true);
  };

  const onCopyClick = async () => {
    setOpenEditPositionCard(false);
    await getWorkspacePositionById(position.id);
    await getPropertyListByCategoryId(position.categoryId);
    setEditPositionCardMode(EEditPositionCardMode.Copy);
    setOpenEditPositionCard(true);
  };

  const onDeleteConfirm = () => {
    deleteWorkspacePosition({
      id: position.id,
      onSuccess: () => {
        setOpenWarningCard(false);
        setOpenEditPositionCard(false);
        showMessage({ content: 'Позиция удалена из карты оснащения.', icon: <BinIcon className="icon-bin-dark-grey" /> });
      },
    });
  };

  const getMenuItems = () => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        label: 'Редактировать позицию',
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
          onEditClick();
        },
      },
      { type: 'divider' },
      {
        key: 'copy',
        label: 'Дублировать позицию',
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
          onCopyClick();
        },
      },
      { type: 'divider' },
      {
        key: 'delete',
        label: 'Удалить из карты',
        onClick: ({ domEvent }) => {
          domEvent.stopPropagation();
          setOpenWarningCard(true);
        },
        className: 'red',
      },
    ];

    return items;
  };

  return (
    <>
      <div className="workspace-position-card-min" onClick={onCardClick}>
        <div className="workspace-position-card-min__header">
          <DocumentIcon className="icon-document-dark-grey" />

          <span className="text-controls-accent" style={{ flex: 1 }}>
            {position.categoryName}
          </span>

          <InventoryCount count={position.inventoryCount} limit={position.inventoryLimit} />

          {editable && (
            <Dropdown overlayClassName="redesign" menu={{ items: getMenuItems() }} placement="bottomRight">
              <Button
                className="button-icon"
                icon={<OptionsIcon className="icon-options-dark-grey" style={{ transform: 'rotate(90deg)' }} />}
              />
            </Dropdown>
          )}
        </div>

        <WorkspacePositionInventoryLimit content={`Норма хранения: ${countFormatter(position.inventoryLimit)}`} />

        {showPresets && (
          <div className="workspace-position-card-min__presets">
            <Preset item={mapCategoryToPreset(position.categoryName)} />

            {position.properties.map((property) => {
              return <Preset key={property.propertyId} item={property} />;
            })}
          </div>
        )}
      </div>

      <WarningDrawer
        open={openWarningCard}
        content="Вы уверены, что хотите удалить позицию из карты оснащения?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={workspacePositionLoading}
        onClose={() => setOpenWarningCard(false)}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  workspacePositionLoading: state.workspacePositionState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  getGoodsList: dispatch.goodsListState.getGoodsList,
  getWorkspacePositionById: dispatch.workspacePositionState.getWorkspacePositionById,
  deleteWorkspacePosition: dispatch.workspacePositionState.deleteWorkspacePosition,
});

export const WorkspacePositionCardMin = connect(mapState, mapDispatch)(Component);
