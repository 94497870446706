import { EPresetType } from 'common/const/preset.enum';
import { numberToString, stringToNumber } from 'common/helpers/common.helper';
import { IFormValues } from 'common/models';
import { ICategory } from 'entities/Categories/Categories.models';
import { getPropertyPresetType, getPropertyRangeResult } from 'entities/Property/Property.helper';
import { IProperty } from 'entities/Property/Property.models';
import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';
import {
  IWorkspaceCatalogCategory,
  IWorkspaceCategory,
  IWorkspaceListGroup,
  IWorkspaceListItem,
  IWorkspacePosition,
} from 'entities/Workspace/Workspace.models';

export const groupWorkspaceList = (list: IWorkspaceListItem[]) => {
  return list.reduce((acc: { subdivisionId: number; subdivisionName: string; dataSource: IWorkspaceListItem[] }[], item) => {
    const { subdivision } = item;
    const existingGroup = acc.find((group) => group.subdivisionId === subdivision?.id);

    if (existingGroup) {
      existingGroup.dataSource.push(item);
    } else {
      acc.push({
        subdivisionId: subdivision?.id,
        subdivisionName: subdivision?.name,
        dataSource: [item],
      });
    }

    return acc;
  }, []);
};

export const sortWorkspaceLowerLevelSubdivisions = (
  subdivisions: ISubdivision[],
  lowerLevelSubdivisions: ISubdivision[],
): ISubdivision[] => {
  const withChildren = lowerLevelSubdivisions.filter((subdivision) => {
    return !!subdivisions.filter((subdivisionItem) => subdivisionItem.parentId === subdivision.id).length;
  });
  const withoutChildren = lowerLevelSubdivisions.filter((subdivision) => {
    return !subdivisions.filter((subdivisionItem) => subdivisionItem.parentId === subdivision.id).length;
  });

  withChildren.sort((a, b) => a.name.localeCompare(b.name));
  withoutChildren.sort((a, b) => a.name.localeCompare(b.name));

  return [...withChildren, ...withoutChildren];
};

export const sortWorkspaceLowerLevelCategories = (
  lowerLevelCategories: IWorkspaceCatalogCategory[],
): IWorkspaceCatalogCategory[] => {
  const withChildren = lowerLevelCategories.filter((category) => !!category.children.length);
  const withoutChildren = lowerLevelCategories.filter((category) => !category.children.length);

  withChildren.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
  withoutChildren.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

  return [...withChildren, ...withoutChildren];
};

export const sortWorkspacePositionLowerLevelCategories = (
  categoryList: ICategory[],
  lowerLevelCategories: ICategory[],
): ICategory[] => {
  const withChildren = lowerLevelCategories.filter((category) => {
    return !!categoryList.filter((categoryItem) => categoryItem.parentId === category.id).length;
  });
  const withoutChildren = lowerLevelCategories.filter((category) => {
    return !categoryList.filter((categoryItem) => categoryItem.parentId === category.id).length;
  });

  return [...withChildren, ...withoutChildren];
};

export const getWorkspacePositionFormInitialValueEntries = (position: IWorkspacePosition) => {
  return position.properties.map((property) => {
    if (property.presetType === EPresetType.SingleValue) {
      return [numberToString(property.propertyId), property.values?.[0]];
    }

    if (property.presetType === EPresetType.Range) {
      return [numberToString(property.propertyId), [{ from: property.range?.from, to: property.range?.to }]];
    }

    return [numberToString(property.propertyId), property.values];
  });
};

export const mapFormValuesToWorkspacePositionPropertyListWithResult = (formValue: IFormValues, propertyList: IProperty[]) => {
  const [key, value] = Object.entries(formValue)[0];

  return propertyList.map((property) => {
    if (property.id === stringToNumber(key)) {
      const { isSingleValue, isMultipleValue, isCheckbox, isRange } = getPropertyPresetType(property);

      if (isSingleValue) {
        return { ...property, result: [value] };
      }

      if (isMultipleValue || isCheckbox) {
        return { ...property, result: value };
      }

      if (isRange) {
        const rangeValue = value[0];

        // eslint-disable-next-line
        if (rangeValue.hasOwnProperty('from')) {
          return { ...property, result: getPropertyRangeResult(rangeValue.from, property.result?.[1]) };
        }

        // eslint-disable-next-line
        if (rangeValue.hasOwnProperty('to')) {
          return { ...property, result: getPropertyRangeResult(property.result?.[0], rangeValue.to) };
        }

        return { ...property, result: [] };
      }
    }

    return property;
  });
};

export const filterGroupedWorkspaceList = (
  groupedWorkspaceList: IWorkspaceListGroup[],
  search: string,
  selectedSubdivisionId?: number,
) => {
  return groupedWorkspaceList
    .map((group) => ({
      ...group,
      dataSource: group.dataSource.filter((workspace) => workspace.name.toLowerCase().includes(search.toLowerCase())),
    }))
    .filter((group) => !!group.dataSource.length)
    .filter((group) => (selectedSubdivisionId ? group.subdivisionId === selectedSubdivisionId : true));
};

export const filterWorkspacePositions = (search: string, categories?: IWorkspaceCategory[], selectedCategoryId?: number) => {
  return categories
    ?.map((category) => {
      return {
        ...category,
        positions: category.positions.filter((position) => {
          return position.categoryName.toLowerCase().includes(search.toLowerCase());
        }),
      };
    })
    .filter((category) => !!category.positions.length)
    .filter((category) => {
      return selectedCategoryId ? category.categoryId === selectedCategoryId : true;
    });
};
