import React, { FC } from 'react';
import { IWorkspaceCategory } from 'entities/Workspace/Workspace.models';
import { WorkspacePositionCardMin } from 'entities/Workspace/components/WorkspacePositionCardMin';

interface IComponentProps {
  group: IWorkspaceCategory;
  editable?: boolean;
  showPresets: boolean;
}

export const WorkspacePositionGroup: FC<IComponentProps> = ({ group, editable, showPresets }) => {
  const sortedPositions = group.positions.sort((a, b) => a.properties.length - b.properties.length);

  if (!sortedPositions.length) {
    return null;
  }

  return (
    <div key={group.categoryId} className="workspace-group">
      <div className="workspace-group__name-container">
        <div className="text-h4-item-name workspace-group__name">{group.categoryName}</div>
      </div>

      <div>
        {sortedPositions.map((position) => (
          <WorkspacePositionCardMin key={position.id} position={position} editable={editable} showPresets={showPresets} />
        ))}
      </div>
    </div>
  );
};
